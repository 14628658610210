<template>
  <div class="openAccountFormEleven">
    <div class="textBigBox">
      <div class="leftBox">
        <div class="itemBox">
          <div class="itemTittleBox">
            <div class="center">
              <span>合約簽署</span>
            </div>
          </div>
        </div>
        <div class="gridBox1">
          <div class="gridItems">
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue1">
              <span>您是否有该申请适用于未在任何监管机构获批或</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue2">
              <span>该申请适用于在单个国家获批或注册的经纪公司</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue3">
              <span>该申请适用于在多个国家获批或注册的经纪公司</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue4">
              <span>主要注册/许可国家或地区</span>
            </div>
          </div>
          <div class="gridItems">
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue5">
              <span>您是否有该申请适用于未在任何监管机构获批或注</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue6">
              <span>该申请适用于在单个国家获批或注册的经纪公司</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue7">
              <span>该申请适用于在多个国家获批或注册的经纪公司</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue8">
              <span>主要注册/许可国家或地区</span>
            </div>
          </div>
          <div class="gridItems">
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue9">
              <span>您是否有该申请适用于未在任何监管机构获批或</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue10">
              <span>该申请适用于在单个国家获批或注册的经纪公司</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue11">
              <span>该申请适用于在多个国家获批或注册的经纪公司</span>
            </div>
            <div class="ItemsBox">
              <input class="checkbox" type="checkbox" v-model="inputValue12">
              <span>主要注册/许可国家或地区</span>
            </div>
          </div>
          <div class="warringText" v-if="chickShow">
            <span>{{warring}}</span>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="itemBox">
          <div class="itemTittleBox">
            <div class="center">
              <span>電子簽名</span>
            </div>
          </div>
        </div>
        <div class="gridBox2">
          <div class="textTittle">
            <span>輸入簽名並通過網路發送即代表您確認該表格內的信息準確無誤，如有不實會受偽證罪處罰，同意數字簽名等同於書面簽名並且同意使用電子記錄作為您證書及協議的證明</span>
          </div>
          <div class="gridBox3">
            <div class="ItemsBox2">
              <div>
                <span>賬戶名稱：</span>
              </div>
              <div class="name">
                <span>{{name}}</span>
              </div>
            </div>
            <div class="ItemsBox2">
              <div>
                <span>時間：</span>
              </div>
              <div>
                <span>{{form.thisYear}}年{{form.thisMonth}}月{{form.thisDate}}日 {{form.thisHH}}：{{form.thisMM}}：{{form.thisSS}}</span>
              </div>
            </div>
          </div>
          <div class="inputBox">
            <div class="inputTittle">
              <span>輸入您的姓名：（需於上方完全一致）</span>
            </div>
            <div class="input">
              <input-text placeholder="Name" v-model="form.name"/>
            </div>
            <div class="warringText" v-if="chickShow">
              <span>{{warring2}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/XuComponents/form/InputText'
export default {
  name:'OpenAccountFormEleven',
  components: {
    InputText,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    },
    name:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      inputValue1:false,
      inputValue2:false,
      inputValue3:false,
      inputValue4:false,
      inputValue5:false,
      inputValue6:false,
      inputValue7:false,
      inputValue8:false,
      inputValue9:false,
      inputValue10:false,
      inputValue11:false,
      inputValue12:false,
      allInputValue:false,
      nameCheckBoolen:false,
      isFormOk:false,
      warring:"*You need to chick it all",
      warring2:"*必須與上方名字一致",
      form:{
        name:"",
        thisYear:"",
        thisMonth:"",
        thisDate:"",
        thisHH:"",
        thisMM:"",
        thisSS:"",
      }
    }
  },
  methods:{
    timeFormate(timeStamp){
      let newdate = new Date(timeStamp);
      let year = newdate.getFullYear();
      let month = newdate.getMonth() + 1 < 10? "0" + (newdate.getMonth() + 1): newdate.getMonth() + 1;
      let date  = newdate.getDate() < 10? "0" + newdate.getDate(): newdate.getDate();
      let hh = newdate.getHours() < 10? "0" + newdate.getHours(): newdate.getHours();
      let mm = newdate.getMinutes() < 10? "0" + newdate.getMinutes(): newdate.getMinutes();
      let ss = newdate.getSeconds() < 10? "0" + newdate.getSeconds(): newdate.getSeconds();
      this.form.thisYear = year
      this.form.thisMonth = month
      this.form.thisDate = date
      this.form.thisHH = hh
      this.form.thisMM = mm
      this.form.thisSS = ss
    },
    nowTimes(){        
      let self = this;
      self.timeFormate(new Date());
      setInterval(function(){
        self.timeFormate(new Date());
      }, 1000);
    },
    nameCheck(){
      if(this.form.name == this.name){
        this.nameCheckBoolen = true
        this.warring2 = ""
      }
      else{
        this.nameCheckBoolen = false
        this.warring2 = "*必須與上方名字一致"
      }
    },
    changPage(){
      if(this.inputValue1 == true 
      && this.inputValue2 == true 
      && this.inputValue3 == true 
      && this.inputValue4 == true 
      && this.inputValue5 == true 
      && this.inputValue6 == true 
      && this.inputValue7 == true 
      && this.inputValue8 == true 
      && this.inputValue9 == true 
      && this.inputValue10 == true 
      && this.inputValue11 == true 
      && this.inputValue12 == true ){
        this.allInputValue = true
        this.warring = ""
      }
      else{
        this.allInputValue = false
        this.warring = "*You need to chick it all"
      }
      this.changPage2()
    },
    changPage2(){
      if(this.nameCheckBoolen == true 
      && this.allInputValue == true){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    }
  },
  mounted(){
    this.nowTimes();
  },
  watch:{
    "inputValue1"(){
      this.changPage()
    },
    "inputValue2"(){
      this.changPage()
    },
    "inputValue3"(){
      this.changPage()
    },
    "inputValue4"(){
      this.changPage()
    },
    "inputValue5"(){
      this.changPage()
    },
    "inputValue6"(){
      this.changPage()
    },
    "inputValue7"(){
      this.changPage()
    },
    "inputValue8"(){
      this.changPage()
    },
    "inputValue9"(){
      this.changPage()
    },
    "inputValue10"(){
      this.changPage()
    },
    "inputValue11"(){
      this.changPage()
    },
    "inputValue12"(){
      this.changPage()
    },
    "form.name"(){
      this.nameCheck()
      this.changPage2()
    }
  }
}
</script>

<style scoped>
.openAccountFormEleven{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  display: flex;
}
.leftBox{
  width: 556px;
  margin-right: 60px;
  height: 100%;
}
.rightBox{
  width: 556px;
  height: 100%;
}
.itemTittleBox{
  width: 100%;
  height: 40px;
  background: #333333;
  border-radius: 9px;
  display: flex;
}
.center{
  height: 100%;
  margin: 0 auto;
  width: 90%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #FBFBFB;
}
.itemBox{
  padding-top: 40px;
  margin-bottom: 40px;
}
.gridBox1{
  width: 95%;
  margin-left: 2.5%;
  height: 65%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.gridBox2{
  width: 95%;
  margin-left: 2.5%;
  height: 65%;
}
.gridItems{
  width: 100%;
  height: 100%;
}
.ItemsBox{
  width: 100%;
  display: flex;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.checkbox{
  width: 21px;
  height: 21px;
  margin-right: 11px;
}
.textTittle{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 40px;
}
.gridBox3{
  width: 95%;
  margin-left: 2.5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin-bottom: 56px;
}
.ItemsBox2{
  width: 100%;
  height: 100%;
}
.ItemsBox2 div:nth-child(1){
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 21px;
}
.ItemsBox2 div:nth-child(2){
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.name{
  margin-left: 50%;
}
.inputBox{
  width: 95%;
  margin-left: 2.5%;
}
.inputTittle{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.warringText{
  color: rgb(255, 0, 0);
  text-align: center;
}
</style>
