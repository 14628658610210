<template>
  <div class="openAccountFormSix">
    <div class="textBigBox">
      <div class="leftBox">
        <div class="itemBox">
          <div class="itemTittleBox">
            <div class="center">
              <span>財務信息</span>
            </div>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox1">
          <div class="itemsTittle">
            <span>年收入</span>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox2">
          <div class="paddingLeft">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.annualIncome" :show="chickShow" @isCheckOkValue="annualIncomeCheck">
            <select-menu :title="form.annualIncome" :width='176'>
              <option-menu v-for="item in annualIncomeList" :key="item.id" @click="annualIncome(item)">{{item.enName}}</option-menu>
            </select-menu>
            </Validator>
          </div>
          <div class="paddingLeft">
            <Validator  type="drowDownMeun" defaultName="DollarType" :titleName="form.dollarType" :show="chickShow" @isCheckOkValue="dollarTypeCheck">
            <select-menu :title="form.dollarType" :width='127'>
              <option-menu v-for="item in dollarTypeList" :key="item.id" @click="dollarType(item)">{{item.enName}}</option-menu>
            </select-menu>
            </Validator>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox3">
          <div class="itemsTittle">
            <span>流動資產淨值</span>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox4">
          <div class="paddingLeft">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.netCurrentAssets" :show="chickShow" @isCheckOkValue="netCurrentAssetsCheck">
            <select-menu :title="form.netCurrentAssets" :width='176'>
              <option-menu v-for="item in netCurrentAssetsList" :key="item.id" @click="netCurrentAssets(item)">{{item.enName}}</option-menu>
            </select-menu>
            </Validator>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox5">
          <div class="itemsTittle">
            <span>淨資產</span>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox6">
          <div class="paddingLeft">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.netAssets" :show="chickShow" @isCheckOkValue="netAssetsCheck">
            <select-menu :title="form.netAssets" :width='176'>
              <option-menu v-for="item in netAssetsList" :key="item.id" @click="netAssets(item)">{{item.enName}}</option-menu>
            </select-menu>
            </Validator>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <div class="itemBox">
          <div class="itemTittleBox">
            <div class="center">
              <span>僱傭</span>
            </div>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox7">
          <div class="leftBox">
            <div class="itemsTittle">
              <span>就業情況</span>
            </div>
            <div class="paddingLeft">
              <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.employmentStatus" :show="chickShow" @isCheckOkValue="employmentStatusCheck">
              <select-menu :title="form.employmentStatus" :width='137'>
                <option-menu v-for="item in employmentStatusList" :key="item.id" @click="employmentStatus(item)">{{item.name}}</option-menu>
              </select-menu>
              </Validator>
            </div>
          </div>
          <div class="rightBox">
            <div class="itemsTittle">
              <span>公司行業</span>
            </div>
            <div class="paddingLeft">
              <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.companyIndustry" :show="chickShow" @isCheckOkValue="companyIndustryCheck">
              <select-menu :title="form.companyIndustry" :width='137'>
                <option-menu v-for="item in companyIndustryList" :key="item.id" @click="companyIndustry(item)">{{item.enName}}</option-menu>
              </select-menu>
              </Validator>
            </div>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox8">
          <div class="leftBox">
            <div class="itemsTittle">
              <span>Country</span>
            </div>
            <div class="paddingLeft">
              <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.country" :show="chickShow" @isCheckOkValue="countryCheck">
              <select-menu :title="form.country" :width='137'>
                <option-menu v-for="item in countryList" :key="item.id" @click="country(item)">{{item.name}}</option-menu>
              </select-menu>
              </Validator>
            </div>
          </div>
          <div class="rightBox">
            <div class="itemsTittle">
              <span>City</span>
            </div>
            <div class="paddingLeft">
              <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.city" :show="chickShow" @isCheckOkValue="cityCheck">
              <select-menu :title="form.city" :width='137'>
                <option-menu v-for="item in cityList" :key="item.id" @click="city(item)">{{item.name}}</option-menu>
              </select-menu>
              </Validator>
            </div>
          </div>
        </div>
        <div class="iconsBox">
          <div class="iconsLeft">
            <div class="itemsTittle">
              <span>District</span>
            </div>
            <div class="inputBox1">
              <Validator type="text" :value="form.district" :show="chickShow" @isCheckOkValue="districtCheck">
              <input-text placeholder="VA" v-model="form.district"/>
              </Validator>
            </div>
          </div>
          <div class="iconsRight">
            <div class="itemsTittle">
              <span>Zip</span>
            </div>
            <div class="inputBox2">
              <Validator type="number" :value="form.zip" :show="chickShow" @isCheckOkValue="zipCheck">
              <input-text placeholder="24175" v-model="form.zip"/>
              </Validator>
            </div>
          </div>
        </div>
        <div class="iconsBox">
          <div class="itemsTittle">
            <span>Contact Address</span>
          </div>
        </div>
        <div class="iconsBox">
          <div class="inputBox3">
            <Validator :value="form.contactAddress" :show="chickShow" @isCheckOkValue="contactAddressCheck">
            <input-text placeholder="303 Meadowview Drive" v-model="form.contactAddress"/>
            </Validator>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox5">
          <div class="itemsTittle">
            <span>職位</span>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox6">
          <div class="paddingLeft">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.jobs" :show="chickShow" @isCheckOkValue="jobsCheck">
            <select-menu :title="form.jobs" :width='176'>
              <option-menu v-for="item in jobsList" :key="item.id" @click="jobs(item)">{{item.enName}}</option-menu>
            </select-menu>
            </Validator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import InputText from '@/components/XuComponents/form/InputText'
import {getDictById,CityList,CountryList} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormSix',
  components: {
    SelectMenu,
    OptionMenu,
    InputText,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      annualIncomeList:[],
      dollarTypeList:[],
      netCurrentAssetsList:[],
      netAssetsList:[],
      employmentStatusList:[
        {
          name:"在職",
        },
        {
          name:"自雇",
        },
        {
          name:"失業",
        },
      ],
      companyIndustryList:[],
      countryList:[],
      jobsList:[],
      cityList:[],
      isFormOk:false,
      form:{
        annualIncome:"Select",
        dollarType:"DollarType",
        netCurrentAssets:"Select",
        netAssets:"Select",
        employmentStatus:"Select",
        companyIndustry:"Select",
        country:"Select",
        city:"Select",
        district:"",
        zip:"",
        contactAddress:"",
        jobs:"Select",
      },
      isCheckAllOkForm:{},

      checkList:[
        {name:"annualIncomeCheck"},
        {name:"dollarTypeCheck"},
        {name:"netCurrentAssetsCheck"},
        {name:"netAssetsCheck"},
        {name:"employmentStatusCheck"},
        {name:"companyIndustryCheck"},
        {name:"countryCheck"},
        {name:"cityCheck"},
        {name:"districtCheck"},
        {name:"zipCheck"},
        {name:"contactAddressCheck"},
        {name:"jobsCheck"},
      ]
    }
  },
  methods:{
    annualIncome(item){
      this.form.annualIncome = item.enName
    },
    dollarType(item){
      this.form.dollarType = item.enName
    },
    netCurrentAssets(item){
      this.form.netCurrentAssets = item.enName
    },
    netAssets(item){
      this.form.netAssets = item.enName
    },
    employmentStatus(item){
      this.form.employmentStatus = item.name
    },
    companyIndustry(item){
      this.form.companyIndustry = item.enName
    },
    country(item){
      this.form.country = item.name
      CityList(item.id).then(res =>{
        this.cityList = res.data.list
      })
    },
    city(item){
      this.form.city = item.name
    },
    jobs(item){
      this.form.jobs = item.enName
    },
    get(){
      getDictById(19000).then(res =>{
        this.annualIncomeList = res.data.list
        this.netCurrentAssetsList = res.data.list
      })
      getDictById(18000).then(res =>{
        this.dollarTypeList = res.data.list
      })
      getDictById(19100).then(res =>{
        this.netAssetsList = res.data.list
      })
      CountryList().then(res =>{
        this.countryList = res.data.items
      })
      getDictById(21000).then(res =>{ //公司行業
        this.companyIndustryList = res.data.list
      })
      getDictById(22000).then(res =>{ //職位
        this.jobsList = res.data.list
      })
    },
    annualIncomeCheck(CheckBoolen){
      this.isCheckAllOkForm.annualIncomeCheck = CheckBoolen
      this.changPage()
    },
    dollarTypeCheck(CheckBoolen){
      this.isCheckAllOkForm.dollarTypeCheck = CheckBoolen
      this.changPage()
    },
    netCurrentAssetsCheck(CheckBoolen){
      this.isCheckAllOkForm.netCurrentAssetsCheck = CheckBoolen
      this.changPage()
    },
    netAssetsCheck(CheckBoolen){
      this.isCheckAllOkForm.netAssetsCheck = CheckBoolen
      this.changPage()
    },
    employmentStatusCheck(CheckBoolen){
      this.isCheckAllOkForm.employmentStatusCheck = CheckBoolen
      this.changPage()
    },
    companyIndustryCheck(CheckBoolen){
      this.isCheckAllOkForm.companyIndustryCheck = CheckBoolen
      this.changPage()
    },
    countryCheck(CheckBoolen){
      this.isCheckAllOkForm.countryCheck = CheckBoolen
      this.changPage()
    },
    cityCheck(CheckBoolen){
      this.isCheckAllOkForm.cityCheck = CheckBoolen
      this.changPage()
    },
    districtCheck(CheckBoolen){
      this.isCheckAllOkForm.districtCheck = CheckBoolen
      this.changPage()
    },
    zipCheck(CheckBoolen){
      this.isCheckAllOkForm.zipCheck = CheckBoolen
      this.changPage()
    },
    contactAddressCheck(CheckBoolen){
      this.isCheckAllOkForm.contactAddressCheck = CheckBoolen
      this.changPage()
    },
    jobsCheck(CheckBoolen){
      this.isCheckAllOkForm.jobsCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.annualIncomeCheck == true 
      && this.isCheckAllOkForm.dollarTypeCheck == true 
      && this.isCheckAllOkForm.netCurrentAssetsCheck == true 
      && this.isCheckAllOkForm.netAssetsCheck == true 
      && this.isCheckAllOkForm.employmentStatusCheck == true 
      && this.isCheckAllOkForm.companyIndustryCheck == true 
      && this.isCheckAllOkForm.countryCheck == true 
      && this.isCheckAllOkForm.cityCheck == true 
      && this.isCheckAllOkForm.districtCheck == true 
      && this.isCheckAllOkForm.zipCheck == true 
      && this.isCheckAllOkForm.contactAddressCheck == true 
      && this.isCheckAllOkForm.jobsCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },

    
  },
  mounted(){
    this.get()
  }
}
</script>

<style scoped>
.openAccountFormSix{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  display: flex;
}
.leftBox{
  max-width: 432px;
  width: 432px;
  margin-right: 45px;
  height: 100%;
}
.rightBox{
  max-width: 432px;
  width: 432px;
  height: 100%;
}
.itemTittleBox{
  width: 100%;
  height: 40px;
  background: #333333;
  border-radius: 9px;
  display: flex;
}
.center{
  height: 100%;
  margin: 0 auto;
  width: 90%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #FBFBFB;
}
.itemBox{
  padding-top: 40px;
  margin-bottom: 20px;
}
.iconsBox{
  display: flex;
  width: 327px;
  max-width: 327px;
  position: relative;
}
#iconsBox1, #iconsBox3, #iconsBox5{
  margin-bottom: 5px;
}
#iconsBox2, #iconsBox4, #iconsBox6, #iconsBox7, #iconsBox8{
  margin-bottom: 40px;
}
.itemsTittle{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.paddingLeft{
  margin-right: 40px;
  position: relative;
}
.leftBox .iconsBox{
  margin-left: 20px;
}
.inputBox1{
  margin-right: 43px;
  width: 250px;
}
.inputBox2{
  width: 78px;
}
.inputBox3{
  width: 250px;
}
.rightBox .iconsBox{
  margin-left: 20px;
}
</style>
