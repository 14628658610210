<template>
  <div class="borker">
    <div class="left">
        <slot name="leftIcon"></slot>
    </div>
    <div class="right">
      <div class="program">
        <slot name="topIcon"></slot>
      </div>
      <div class="pageBox">
          <slot name="pageForm"></slot>
      </div>
      <div class="btnBox">
          <slot name="backIcon"></slot>
          <slot name="nextIcon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'JoinUsFrame',
  components: {

  },
  data() {
    return {

    }
  },
  methods:{},
}
</script>

<style scoped>
    *{
    position: relative;
  }
  .borker{
    position: relative;
    width: 100vw;
    height: 100%;
    background: #f9f9f9;
    display: flex;
  }
  .left{
    width: 20%;
    height: 100%;
    /* border: 1px red solid; */
    display: inline-block;
  }
  .right{
    width: 80%;
    height: 100%;
    /* border: 1px rgb(0, 60, 255) solid; */
    display: inline-block;
  }
  .program{
    margin-top: 37px;
    width: 100%;
    height: 115px;
    /* border: 1px rgb(102, 255, 0) solid; */
  }
  .pageBox{
    width: 100%;
    height: 65%;
    /* border: 1px rgb(255, 0, 0) solid; */
  }
  .btnBox{
    width: 100%;
    height: 50px;
    /* border: 1px rgb(55, 0, 80) solid; */
    display: flex;
  }
  .btnBack{
    position: absolute;
    left: 50px;
    top: 0;
  }
  .btnNext{
    position: absolute;
    right: 50px;
    top: 0;
  }
</style>
