<template>
  <div class="openAccountFormFive">
    <div class="textBigBox">
      <div class="leftBox">
        <div class="itemBox">
          <div class="itemTittleBox">
            <div class="center">
              <span>稅務信息</span>
            </div>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox1">
          <div class="leftBox">
            <div class="itemsTittle">
              <span>Country</span>
            </div>
            <div class="paddingLeft">
              <Validator type="drowDownMeun" defaultName="Select" :titleName="form.taxCountry" :show="chickShow" @isCheckOkValue="taxCountryCheck">
              <select-menu :title="form.taxCountry" :width='137'>
                <option-menu v-for="item in taxCountryList" :key="item.id" @click="taxCountry(item)">{{item.name}}</option-menu>
              </select-menu>
              </Validator>
            </div>
          </div>
          <div class="rightBox">
            <div class="itemsTittle">
              <span>City</span>
            </div>
            <div class="paddingLeft">
              <Validator type="drowDownMeun" defaultName="Select" :titleName="form.taxCity" :show="chickShow" @isCheckOkValue="taxCityCheck">
                <select-menu :title="form.taxCity" :width='137'>
                  <option-menu v-for="item in taxCityList" :key="item.id" @click="taxCity(item)">{{item.name}}</option-menu>
                </select-menu>
              </Validator>
            </div>
          </div>
        </div>
        <div class="iconsBox">
          <div>
            <input class="checkbox" type="radio" name="checkTex" v-model="form.checkTexBoolen" :value="true" @click="checkTex">
          </div>
          <div class="itemsText">
            <span>美國納稅人識別號</span>
          </div>
        </div>
        <div class="inputBox">
          <Validator :value="form.USATex" :show="chickShow" @isCheckOkValue="USATexCheck">
          <input-text placeholder="Plese fill in" v-model="form.USATex" :disabled="!form.checkTexBoolen"/>
          </Validator>
        </div>
        <div class="iconsBox">
          <div>
            <input class="checkbox" type="radio" name="checkTex" @click="checkTex2">
          </div>
          <div class="itemsText">
            <span>海外識別號</span>
          </div>
        </div>
        <div class="inputBox">
          <Validator :value="form.abroadTex" :show="chickShow" @isCheckOkValue="abroadTexCheck">
          <input-text placeholder="Plese fill in" v-model="form.abroadTex" :disabled="form.checkTexBoolen"/>
          </Validator>
        </div>
      </div>
      <div class="rightBox">
        <div class="itemBox">
          <div class="itemTittleBox">
            <div class="center">
              <span>協議優惠資格</span>
            </div>
          </div>
        </div>
        <div class="iconsBox" id="iconsBox2">
          <div>
            <input class="checkbox" type="checkbox" v-model="form.discountBoolen">
          </div>
          <div class="itemsText">
            <span>我確認我是下方國家居民，屬於美國於該國之間所達成的所得稅協議作用範圍之內</span>
          </div>
          
        </div>
        <div class="iconsBox">
          <div class="paddingLeft">
            <Validator type="drowDownMeun" defaultName="Country" :titleName="form.discountCountry" :show="chickShow" @isCheckOkValue="discountCountryCheck">
            <select-menu :title="form.discountCountry" :width='137'>
              <option-menu v-for="item in discountCountryList" :key="item.id" @click="discountCountry(item)">{{item.name}}</option-menu>
            </select-menu>
            </Validator>
            <div class="mask" v-if="!form.discountBoolen"></div>
          </div>
          <div class="paddingLeft">
            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.discountCity" :show="chickShow" @isCheckOkValue="discountCityCheck">
            <select-menu :title="form.discountCity" :width='137'>
              <option-menu v-for="item in discountCityList" :key="item.id" @click="discountCity(item)">{{item.enName}}</option-menu>
            </select-menu>
            </Validator>
            <div class="mask" v-if="!form.discountBoolen"></div>
          </div>
        </div>
        <div class="iconsBox" id="iconsBoxBottom">
          <div class="itemsTittle">
            <span>證件號碼</span>
          </div>
        </div>
        <div class="iconsBox">
          <div class="inputBox">
            <Validator :value="form.IdNumber" :show="chickShow" @isCheckOkValue="IdNumberCheck">
            <input-text placeholder="Plese fill in" v-model="form.IdNumber" />
            </Validator>
          </div>
          <div class="mask" v-if="!form.discountBoolen"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import InputText from '@/components/XuComponents/form/InputText'
import {CountryList,CityList,getDictById} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormFive',
  components: {
    SelectMenu,
    OptionMenu,
    InputText,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      
      
      taxCountryList:[],
      taxCityList:[],
      discountCountryList:[],
      discountCityList:[],
      isFormOk:false,
      form:{
        taxCountry:"Select",
        taxCity:"Select",
        USATex:"",
        abroadTex:"",
        discountCountry:"Country",
        discountCity:"Select",
        IdNumber:"",
        checkTexBoolen:true,
        discountBoolen:false,
      },
      isCheckAllOkForm:{}
    }
  },
  methods:{
    taxCountry(item){
      this.form.taxCountry = item.name
      CityList(item.id).then(res=>{
        this.taxCityList = res.data.list
      })
    },
    taxCity(item){
      this.form.taxCity = item.name
    },
    discountCountry(item){
      this.form.discountCountry = item.name
    },
    discountCity(item){
      this.form.discountCity = item.enName
    },
    checkTex(){
      this.checkTexBoolen = true
    },
    checkTex2(){
      this.checkTexBoolen = false
    },
    get(){
      CountryList().then(res=>{
        this.taxCountryList = res.data.items
        this.discountCountryList = res.data.items
      })
      getDictById(10000).then(res =>{
        this.discountCityList = res.data.list
      })
    },
    taxCountryCheck(CheckBoolen){
      this.isCheckAllOkForm.taxCountryCheck = CheckBoolen
      this.changPage()
    },
    taxCityCheck(CheckBoolen){
      this.isCheckAllOkForm.taxCityCheck = CheckBoolen
      this.changPage()
    },
    USATexCheck(CheckBoolen){
      this.isCheckAllOkForm.USATexCheck = CheckBoolen
      this.twoPickOne()
    },
    abroadTexCheck(CheckBoolen){
      this.isCheckAllOkForm.abroadTexCheck = CheckBoolen
      this.twoPickOne()
    },
    twoPickOne(){
      if(this.isCheckAllOkForm.USATexCheck == true 
      || this.isCheckAllOkForm.abroadTexCheck == true ){
        this.isCheckAllOkForm.twoPickOne = true
      }
      else{
        this.isCheckAllOkForm.twoPickOne = false
      }
      this.changPage()
    },
    discountCountryCheck(CheckBoolen){
      this.isCheckAllOkForm.discountCountryCheck = CheckBoolen
      this.changPage()
    },
    discountCityCheck(CheckBoolen){
      this.isCheckAllOkForm.discountCityCheck = CheckBoolen
      this.changPage()
    },
    IdNumberCheck(CheckBoolen){
      this.isCheckAllOkForm.IdNumberCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.taxCountryCheck == true 
      && this.isCheckAllOkForm.taxCityCheck == true 
      && this.isCheckAllOkForm.twoPickOne == true 
      && this.isCheckAllOkForm.discountCountryCheck == true 
      && this.isCheckAllOkForm.discountCityCheck == true 
      && this.isCheckAllOkForm.IdNumberCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
    this.get()
  },
}
</script>

<style scoped>
.openAccountFormFive{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  display: flex;
}
.leftBox{
  /* border: 1px rgb(72, 255, 0) solid; */
  max-width: 432px;
  width: 432px;
  margin-right: 45px;
  height: 100%;
}
.rightBox{
  /* border: 1px rgb(72, 255, 0) solid; */
  max-width: 432px;
  width: 432px;
  height: 100%;
}
.itemTittleBox{
  width: 100%;
  height: 40px;
  background: #333333;
  border-radius: 9px;
  display: flex;
}
.center{
  height: 100%;
  margin: 0 auto;
  width: 90%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #FBFBFB;
}
.itemBox{
  padding-top: 40px;
  margin-bottom: 20px;
}
.iconsBox{
  display: flex;
  width: 327px;
  max-width: 327px;
  position: relative;
}
.itemsTittle{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.checkbox{
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
/* .iconsBox{
  margin-bottom: 20px;
} */
.itemsText{
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
#iconsBox1{
  margin-bottom: 20px;
}
.rightBox .iconsBox{
  padding-left: 40px;
  margin-bottom: 20px;
}
.paddingLeft{
  margin-right: 40px;
  position: relative;
}
#iconsBoxBottom{
  margin-bottom: 0;
}
#iconsBox2{
  margin-bottom: 20px;
  padding-left: 15px;
  position: relative;
}
.mask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
</style>
