<template>
  <div class="openAccountFormSeven">
    <div class="textBigBox">
      <div class="itemBox">
        <div class="itemTittleBox">
          <div class="center">
            <span>規管信息</span>
          </div>
        </div>
      </div>
      <div>
        <div class="marginLeft">
          <div class="itemsTittle">
            <span>賬戶持有人是否是任意上市公司的董事/執行官，或者擁有上市公司10%及以上股權的人士？</span>
          </div>
        </div>
        <div class="marginLeft">
          <div class="twoToOneBox">
            <div class="marginRight">
              <div class="twoToOne" @click="TwoToOneBtn1">
                <TwoToOneBtn :Active="form.Active1" titleName="Yes"></TwoToOneBtn>
              </div>
            </div>
            <div class="twoToOne" @click="TwoToOneBtn2">
              <TwoToOneBtn :Active="!form.Active1" titleName="No"></TwoToOneBtn>
            </div>
          </div>
        </div>
        <div class="marginLeft">
          <div class="itemsTittle">
            <span>輸入公司的股票代碼，以逗號(，)隔開：</span>
          </div>
        </div>
        <div class="marginLeft">
          <div class="inputTextBox">
            <Validator :value="form.companyStockCode" :show="chickShow" @isCheckOkValue="companyStockCodeCheck">
            <input-text placeholder="" v-model="form.companyStockCode"/>
            </Validator>
          </div>
        </div>
        <div class="marginLeft">
          <div class="itemsTittle">
            <span>你是否為金融從業人員</span>
          </div>
        </div>
        <div class="marginLeft">
          <div class="twoToOneBox">
            <div class="marginRight">
              <div class="twoToOne" @click="TwoToOneBtn3">
                <TwoToOneBtn :Active="form.Active2" titleName="Yes"></TwoToOneBtn>
              </div>
            </div>
            <div class="twoToOne" @click="TwoToOneBtn4">
              <TwoToOneBtn :Active="!form.Active2" titleName="No"></TwoToOneBtn>
            </div>
          </div>
        </div>
        <div class="marginLeft">
          <div class="itemsTittle">
            <span>你服務的公司名稱</span>
          </div>
        </div>
        <div class="marginLeft">
          <div class="inputTextBox">
            <Validator type="text" :value="form.companyName" :show="chickShow" @isCheckOkValue="companyNameCheck">
            <input-text placeholder="" v-model="form.companyName"/>
            </Validator>
          </div>
        </div>
        <div class="marginLeft">
          <div class="itemsTittle">
            
            <span>崗位</span>
          </div>
        </div>
        <div class="marginLeft">
          <div class="inputTextBox">
            <Validator type="text" :value="form.post" :show="chickShow" @isCheckOkValue="postCheck">
              <input-text placeholder="" v-model="form.post"/>
            </Validator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoToOneBtn from '@/components/XuComponents/btn/TwoToOneBtn'
import InputText from '@/components/XuComponents/form/InputText'
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormSeven',
  components: {
    TwoToOneBtn,
    InputText,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      form:{
        companyStockCode:"",
        companyName:"",
        post:"",
        Active1:false,
        Active2:false,
      },
      isCheckAllOkForm:{},
    }
  },
  methods:{
    TwoToOneBtn1(){
      this.form.Active1 = true
    },
    TwoToOneBtn2(){
      this.form.Active1 = false
    },
    TwoToOneBtn3(){
      this.form.Active2 = true
    },
    TwoToOneBtn4(){
      this.form.Active2 = false
    },
    companyStockCodeCheck(CheckBoolen){
      this.isCheckAllOkForm.companyStockCodeCheck = CheckBoolen
      this.changPage()
    },
    companyNameCheck(CheckBoolen){
      this.isCheckAllOkForm.companyNameCheck = CheckBoolen
      this.changPage()
    },
    postCheck(CheckBoolen){
      this.isCheckAllOkForm.postCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.companyStockCodeCheck == true 
      && this.isCheckAllOkForm.companyNameCheck == true 
      && this.isCheckAllOkForm.postCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
}
</script>

<style scoped>
.openAccountFormSeven{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  width: 861px;
  max-width: 861px;
}
.itemTittleBox{
  width: 100%;
  height: 40px;
  background: #333333;
  border-radius: 9px;
  display: flex;
}
.center{
  height: 100%;
  margin: 0 auto;
  width: 90%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #FBFBFB;
}
.itemBox{
  padding-top: 40px;
  margin-bottom: 20px;
}
.marginLeft{
  margin-left: 20px;
}
.marginLeft:nth-child(1), .marginLeft:nth-child(3), .marginLeft:nth-child(5), .marginLeft:nth-child(7), .marginLeft:nth-child(9){
  margin-bottom: 5px;
}
.marginLeft:nth-child(2), .marginLeft:nth-child(6), .marginLeft:nth-child(8), .marginLeft:nth-child(10){
  margin-bottom: 25px;
}
.itemsTittle{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.twoToOneBox{
  display: flex;
}
.marginRight{
  margin-right: 14px;
}
.twoToOne{
  width: 90px;
  height: 30px;
}
.inputTextBox{
  width: 600px;
}
.warringText{
  color: rgb(255, 0, 0);
  text-align: left;
}
</style>
