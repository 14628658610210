<template>
  <div class="openAccountFormFour">
    <div class="textBigBox">
        <div class="itemBox" id="topPadding">
            <div class="itemTittleBox">
                <div class="center">
                    <span>個人基本信息</span>
                </div>
            </div>
        </div>
        <div class="itemBox">
            <div class="boxCenter">
                <div class="leftBox">
                    <div class="itemsTittle">
                        <span>Name</span>
                    </div>
                    <div class="iconsBox">
                        <div class="paddingLeft">
                            <Validator type="text" :value="form.fristName" :show="chickShow" @isCheckOkValue="fristNameCheck">
                                <input-text placeholder="George" v-model="form.fristName"/>
                            </Validator>
                        </div>
                        <div class="paddingLeft">
                            <Validator type="text" :value="form.middleName" :show="chickShow" @isCheckOkValue="middleNameCheck">
                                <input-text placeholder="Mars" v-model="form.middleName"/>
                            </Validator>
                        </div>
                        <div class="paddingLeft">
                            <Validator type="text" :value="form.lastName" :show="chickShow" @isCheckOkValue="lastNameCheck">
                            <input-text placeholder="Roberts" v-model="form.lastName"/>
                            </Validator>
                        </div>
                    </div>
                    <div class="itemsTittle">
                        <span>Date of Birth</span>
                    </div>
                    <div class="iconsBox" id="iconsBox2">
                        
                        <div class="paddingLeft">
                            <Validator type="drowDownMeun" defaultName="" :titleName="form.dateBirth" :show="chickShow" @isCheckOkValue="dateBirthCheck">
                            <el-date-picker
                                v-model="form.dateBirth"
                                type="date"
                                placeholder="Choose Date"
                                value-format="YYYY-MM-DDTHH:mm:ss">
                            </el-date-picker>
                            </Validator>
                        </div>
                    </div>
                    <div class="itemsTittle">
                        <span>Gender</span>
                    </div>
                    <div class="iconsBox">
                        <div class="paddingLeft" id="iconsBox3">
                            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.gender" :show="chickShow" @isCheckOkValue="genderCheck">
                                <select-menu :title="form.gender" :width='100'>
                                    <option-menu v-for="item in genderList" :key="item.id" @click="gender(item)">{{item.name}}</option-menu>
                                </select-menu>
                            </Validator>
                        </div>
                    </div>
                    <div class="itemsTittle">
                        <span>Contact method</span>
                    </div>
                    <div class="iconsBox">
                        <div class="paddingLeft" id="iconsBox3">
                            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.numberTittle" :show="chickShow" @isCheckOkValue="numberTittleCheck">
                            <select-menu :title="form.numberTittle" :width='80'>
                                <option-menu v-for="item in numberTittleList" :key="item.id" @click="numberTittle(item)">{{item.phonecode}}</option-menu>
                            </select-menu>
                            </Validator>
                        </div>
                        <div class="paddingLeft">
                            <Validator type="number" :value="form.phoneNumber" :show="chickShow" @isCheckOkValue="phoneNumberCheck">
                            <input-text placeholder="Plese fill in" v-model="form.phoneNumber"/>
                            </Validator>
                        </div>
                    </div>
                </div>
                <div class="marginBox"></div>
                <div  class="rightBox">
                    <div class="iconsBox" id="iconsBox4">
                        <div class="leftBox">
                            <div class="itemsTittle">
                                <span>Country</span>
                            </div>
                            <div class="paddingLeft">
                                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.country" :show="chickShow" @isCheckOkValue="countryCheck">
                                <select-menu :title="form.country" :width='100'>
                                    <option-menu v-for="item in countryList" :key="item.id" @click="country(item)">{{item.name}}</option-menu>
                                </select-menu>
                                </Validator>
                            </div>
                        </div>
                        <div class="marginBox2"></div>
                        <div class="rightBox">
                            <div class="itemsTittle">
                                <span>City</span>
                            </div>
                            <div class="paddingLeft">
                                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.city" :show="chickShow" @isCheckOkValue="cityCheck">
                                <select-menu :title="form.city" :width='100'>
                                    <option-menu v-for="item in cityList" :key="item.id" @click="city(item)">{{item.name}}</option-menu>
                                </select-menu>
                                </Validator>
                            </div>
                        </div>
                    </div>
                    <div class="iconsBox">
                        <div class="leftBox">
                            <div class="itemsTittle">
                                <span>Contact Address</span>
                            </div>
                            <div class="paddingLeft">
                                <Validator :value="form.contactAddress" :show="chickShow" @isCheckOkValue="contactAddressCheck">
                                <input-text placeholder="Plese fill in" v-model="form.contactAddress"/>
                                </Validator>
                            </div>
                        </div>
                        <div class="marginBox3"></div>
                        <div class="rightBox">
                            <div class="itemsTittle">
                                <span>Zip</span>
                            </div>
                            <div class="paddingLeft">
                                <Validator :value="form.zip" :show="chickShow" @isCheckOkValue="zipCheck">
                                <input-text placeholder="Plese fill in" v-model="form.zip"/>
                                </Validator>
                            </div>
                        </div>
                    </div>
                    <div class="itemsTittle">
                        <span>District</span>
                    </div>
                    <div class="iconsBox" id="iconsBox5">
                        <div class="paddingLeft">
                            <Validator type="text" :value="form.district" :show="chickShow" @isCheckOkValue="districtCheck">
                            <input-text placeholder="Plese fill in" v-model="form.district"/>
                            </Validator>
                        </div>
                    </div>
                    <div class="itemsTittle">
                        <span>Emergency Contact</span>
                    </div>
                    <div class="iconsBox">
                        <div class="paddingLeft">
                            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.emergencyContactGender" :show="chickShow" @isCheckOkValue="emergencyContactGenderCheck">
                            <select-menu :title="form.emergencyContactGender" :width='100'>
                                <option-menu v-for="item in emergencyContactGenderList" :key="item.id" @click="emergencyContactGender(item)">{{item.name}}</option-menu>
                            </select-menu>
                            </Validator>
                        </div>
                        <div class="paddingLeft">
                            <Validator type="text" :value="form.emergencyContactFristName" :show="chickShow" @isCheckOkValue="emergencyContactFristNameCheck">
                            <input-text placeholder="George" v-model="form.emergencyContactFristName"/>
                            </Validator>
                        </div>
                        <div class="paddingLeft">
                            <Validator type="text" :value="form.emergencyContactMiddleName" :show="chickShow" @isCheckOkValue="emergencyContactMiddleNameCheck">
                            <input-text placeholder="Mars" v-model="form.emergencyContactMiddleName"/>
                            </Validator>
                        </div>
                        <div class="paddingLeft">
                            <Validator type="text" :value="form.emergencyContactLastName" :show="chickShow" @isCheckOkValue="emergencyContactLastNameCheck">
                            <input-text placeholder="Roberts" v-model="form.emergencyContactLastName"/>
                            </Validator>
                        </div>
                    </div>
                    <div class="iconsBox">
                        <div class="paddingLeft">
                            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.emergencyContactNumberTittle" :show="chickShow" @isCheckOkValue="emergencyContactNumberTittleCheck">
                            <select-menu :title="form.emergencyContactNumberTittle" :width='100'>
                                <option-menu v-for="item in emergencyContactNumberTittleList" :key="item.id" @click="emergencyContactNumberTittle(item)">{{item.phonecode}}</option-menu>
                            </select-menu>
                            </Validator>
                        </div>
                        <div class="paddingLeft">
                            <Validator type="number" :value="form.emergencyContactPhoneNumber" :show="chickShow" @isCheckOkValue="emergencyContactPhoneNumberCheck">
                            <input-text placeholder="Plese fill in" v-model="form.emergencyContactPhoneNumber"/>
                            </Validator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/XuComponents/form/InputText'
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import {smsCountryList,CountryList,CityList} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormFour',
  components: {
      InputText,
      SelectMenu,
      OptionMenu,
      Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
        genderList:[
            {
                name:"Male",
            },
            {
                name:"Female",
            },
        ],
        numberTittleList:[],
        countryList:[],
        cityList:[],
        emergencyContactGenderList:[
            {
                name:"Male",
            },
            {
                name:"Female",
            },
        ],
        emergencyContactNumberTittleList:[],
        isFormOk:false,
        form:{
            fristName:"",
            middleName:"",
            lastName:"",
            dateBirth:"",
            gender:"Select",
            numberTittle:"Select",
            phoneNumber:"",
            country:"Select",
            city:"Select",
            contactAddress:"",
            zip:"",
            district:"",
            emergencyContactGender:"Select",
            emergencyContactFristName:"",
            emergencyContactMiddleName:"",
            emergencyContactLastName:"",
            emergencyContactNumberTittle:"Select",
            emergencyContactPhoneNumber:"",
        },
        isCheckAllOkForm:{}
    }
  },
  methods:{
      gender(item){
          this.form.gender = item.name
      },
      numberTittle(item){
          this.form.numberTittle = item.phonecode
      },
      country(item){
          this.form.country = item.name
            CityList(item.id).then(res=>{
                this.cityList = res.data.list
            })
      },
      city(item){
          this.form.city = item.name
      },
      emergencyContactGender(item){
          this.form.emergencyContactGender = item.name
      },
      emergencyContactNumberTittle(item){
          this.form.emergencyContactNumberTittle = item.phonecode
      },
      get(){
          smsCountryList().then(res=>{
              this.emergencyContactNumberTittleList = res.data.list
              this.numberTittleList = res.data.list
          })
          CountryList().then(res=>{
              this.countryList = res.data.items
          })
      },
      fristNameCheck(CheckBoolen){
          this.isCheckAllOkForm.fristNameCheck = CheckBoolen
          this.changPage()
      },
      middleNameCheck(CheckBoolen){
          this.isCheckAllOkForm.middleNameCheck = CheckBoolen
          this.changPage()
      },
      lastNameCheck(CheckBoolen){
          this.isCheckAllOkForm.lastNameCheck = CheckBoolen
          this.changPage()
      },
      dateBirthCheck(CheckBoolen){
          this.isCheckAllOkForm.dateBirthCheck = CheckBoolen
          this.changPage()
      },
      genderCheck(CheckBoolen){
          this.isCheckAllOkForm.genderCheck = CheckBoolen
          this.changPage()
      },
      numberTittleCheck(CheckBoolen){
          this.isCheckAllOkForm.numberTittleCheck = CheckBoolen
          this.changPage()
      },
      phoneNumberCheck(CheckBoolen){
          this.isCheckAllOkForm.phoneNumberCheck = CheckBoolen
          this.changPage()
      },
      countryCheck(CheckBoolen){
          this.isCheckAllOkForm.countryCheck = CheckBoolen
          this.changPage()
      },
      cityCheck(CheckBoolen){
          this.isCheckAllOkForm.cityCheck = CheckBoolen
          this.changPage()
      },
      contactAddressCheck(CheckBoolen){
          this.isCheckAllOkForm.contactAddressCheck = CheckBoolen
          this.changPage()
      },
      zipCheck(CheckBoolen){
          this.isCheckAllOkForm.zipCheck = CheckBoolen
          this.changPage()
      },
      districtCheck(CheckBoolen){
          this.isCheckAllOkForm.districtCheck = CheckBoolen
          this.changPage()
      },
      emergencyContactGenderCheck(CheckBoolen){
          this.isCheckAllOkForm.emergencyContactGenderCheck = CheckBoolen
          this.changPage()
      },
      emergencyContactFristNameCheck(CheckBoolen){
          this.isCheckAllOkForm.emergencyContactFristNameCheck = CheckBoolen
          this.changPage()
      },
      emergencyContactMiddleNameCheck(CheckBoolen){
          this.isCheckAllOkForm.emergencyContactMiddleNameCheck = CheckBoolen
          this.changPage()
      },
      emergencyContactLastNameCheck(CheckBoolen){
          this.isCheckAllOkForm.emergencyContactLastNameCheck = CheckBoolen
          this.changPage()
      },
      emergencyContactNumberTittleCheck(CheckBoolen){
          this.isCheckAllOkForm.emergencyContactNumberTittleCheck = CheckBoolen
          this.changPage()
      },
      emergencyContactPhoneNumberCheck(CheckBoolen){
          this.isCheckAllOkForm.emergencyContactPhoneNumberCheck = CheckBoolen
          this.changPage()
      },
      changPage(){
      if(this.isCheckAllOkForm.fristNameCheck == true 
      && this.isCheckAllOkForm.middleNameCheck == true 
      && this.isCheckAllOkForm.lastNameCheck == true 
      && this.isCheckAllOkForm.dateBirthCheck == true 
      && this.isCheckAllOkForm.genderCheck == true 
      && this.isCheckAllOkForm.numberTittleCheck == true 
      && this.isCheckAllOkForm.phoneNumberCheck == true 
      && this.isCheckAllOkForm.countryCheck == true 
      && this.isCheckAllOkForm.cityCheck == true 
      && this.isCheckAllOkForm.contactAddressCheck == true 
      && this.isCheckAllOkForm.zipCheck == true 
      && this.isCheckAllOkForm.districtCheck == true 
      && this.isCheckAllOkForm.emergencyContactGenderCheck == true 
      && this.isCheckAllOkForm.emergencyContactFristNameCheck == true 
      && this.isCheckAllOkForm.emergencyContactMiddleNameCheck == true 
      && this.isCheckAllOkForm.emergencyContactLastNameCheck == true 
      && this.isCheckAllOkForm.emergencyContactNumberTittleCheck == true 
      && this.isCheckAllOkForm.emergencyContactPhoneNumberCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
      this.get()
  }
}
</script>

<style scoped>
.openAccountFormFour{
    width: 100%;
    height: 100%;
    display: flex;
}
.textBigBox{
    height: 100%;
    margin: 0 auto;
    max-width: 878px;
    width: 878px;
}
.itemTittleBox{
    width: 100%;
    height: 40px;
    background: #333333;
    border-radius: 9px;
    display: flex;
}
.center{
    height: 100%;
    margin: 0 auto;
    width: 90%;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: #FBFBFB;
}
.itemBox:nth-child(2){
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
}
.boxCenter{
    height: 100%;
    margin: 0 auto;
    display: flex
}
.marginBox{
    max-width: 128px;
    width: 128px;
    height: 100%;
}
.marginBox2{
    max-width: 64px;
    width: 64px;
    height: 100%;
}
.marginBox3{
    max-width: 21px;
    width: 21px;
    height: 100%;
}
.itemsTittle{
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}
.iconsBox{
    display: flex;
    width: 327px;
    max-width: 327px;
}
.paddingLeft{
    padding-right: 5px;
}
#iconsBox2{
    margin-bottom: 20px;
}
#iconsBox3{
    margin-bottom: 67px;
}
#iconsBox4{
    margin-bottom: 20px;
}
#iconsBox5{
    margin-bottom: 60px;
}
#topPadding{
    padding-top: 40px;
}
</style>
