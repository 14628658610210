<template>
  <div id="TwoToOneBtn組件">
    <div id="Active" v-if="Active">
        <div class="titleNameBox">
            <span>{{titleName}}</span>
        </div>
    </div>
    <div id="UnActive" v-if="!Active">
        <div class="titleNameBox">
            <span>{{titleName}}</span>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'TwoToOneBtn',
  components: {

  },
  props:{
      Active:{
        type:Boolean,
        default:true,
      },
      titleName:{
        type:String,
        default:"",
      },
  },
  data() {
    return {
        
    }
  },
  methods:{

  },
}
</script>

<style scoped>
    @import url(https://cdn.bootcss.com/font-awesome/5.13.0/css/all.css);
    *{
    margin: 0;
    padding: 0;
    position: relative;
    font-family: Helvetica, "PingFang SC", "Microsoft Yahei", sans-serif;
    /* border: #FF004D 1px solid; */
    transition: .5s;
    }   
    #TwoToOneBtn組件{
        width: 100%;
        height: 100%;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
        border-radius: 50px;
        cursor: pointer;
    }
    #Active{
        width: 100%;
        height: 100%;
        background: #3C7AD9;
        border-radius: 50px;
        display: flex;
    }
    #Active div{
        margin: 0 auto;
        height: 100%;
        /* border: 1px solid red; */
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 30px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #FFFFFF;
        font-weight: 500;
    }
    #UnActive{
        width: 100%;
        height: 100%;
        background: #E9E9E9;
        border-radius: 50px;
        display: flex;
    }
    #UnActive div{
        margin: 0 auto;
        height: 100%;
        /* border: 1px solid red; */
        font-family: Inter;
        font-style: normal;
        font-size: 14px;
        line-height: 30px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #000000;
        font-weight: 500;
    }
    .titleNameBox{
      padding: 0 15px;
    }
</style>
