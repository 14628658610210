<template>
  <div class="openAccountApply">
    <JoinUsFrame>
      <template v-slot:leftIcon>
        <LeftBar :page="page">
          <template v-slot:spanBox>
            <div class="divBox">
              <div class="centerLine">
                <div class="span">
                  <span>{{leftBarTittle}}</span>
                </div>
                <div class="span">
                  <span>{{leftBarTextTwo}}</span>
                </div>
                <div class="span">
                  <span>遇上問題? 技術支持</span>
                </div>
                <div class="span">
                  <span>關於隱私</span>
                </div>
                <div class="span">
                  <span>知識產權</span>
                </div>
                <div class="span">
                  <span>網路安全法</span>
                </div>
              </div>
            </div>
          </template>
        </LeftBar>
      </template>
      <template v-slot:topIcon>
        <div class="NumberNavBarHiddenBox">
          <div class="NumberNavBarHidden">
            <div ref="NumberNavBar" class="NumberNavBar">
              <NumberNavBar :page="page" :allPage="allPage" :startPage="2" >
                <template v-slot:1>
                  <span>Personal information1</span>
                </template>
                <template v-slot:13>
                  <span>About you1</span>
                </template>
                <template v-slot:2>
                  <span>Personal information1</span>
                </template>
                <template v-slot:14>
                  <span>About you1</span>
                </template>
                <template v-slot:3>
                  <span>Personal information1</span>
                </template>
                <template v-slot:15>
                  <span>About you1</span>
                </template>
                <template v-slot:4>
                  <span>Personal information1</span>
                </template>
                <template v-slot:16>
                  <span>About you1</span>
                </template>
                <template v-slot:5>
                  <span>Personal information1</span>
                </template>
                <template v-slot:17>
                  <span>About you1</span>
                </template>
                <template v-slot:6>
                  <span>Personal information1</span>
                </template>
                <template v-slot:18>
                  <span>About you1</span>
                </template>
                <template v-slot:7>
                  <span>Personal information1</span>
                </template>
                <template v-slot:19>
                  <span>About you1</span>
                </template>
                <template v-slot:8>
                  <span>Personal information1</span>
                </template>
                <template v-slot:20>
                  <span>About you1</span>
                </template>
                <template v-slot:9>
                  <span>Personal information1</span>
                </template>
                <template v-slot:21>
                  <span>About you1</span>
                </template>
                <template v-slot:10>
                  <span>Personal information1</span>
                </template>
                <template v-slot:22>
                  <span>About you1</span>
                </template>
                <template v-slot:11>
                  <span>Personal information1</span>
                </template>
                <template v-slot:23>
                  <span>About you1</span>
                </template>
                <template v-slot:12>
                  <span>Personal information1</span>
                </template>
                <template v-slot:24>
                  <span>About you1</span>
                </template>
              </NumberNavBar>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:pageForm>
        <OpenAccountFormOne v-if="page==1" @form="pageOneForm"></OpenAccountFormOne>
        <OpenAccountFormTwo v-if="page==2" @form="pageTwoForm"></OpenAccountFormTwo>
        <OpenAccountFormThree v-if="page==3" @isFormOk="isFormOk" @form="pageThreeForm" :chickShow="pageChickShow"></OpenAccountFormThree>
        <OpenAccountFormFour v-if="page==4" @isFormOk="isFormOk" @form="pageFourForm" :chickShow="pageChickShow"></OpenAccountFormFour>
        <OpenAccountFormFive v-if="page==5" @isFormOk="isFormOk" @form="pageFiveForm" :chickShow="pageChickShow"></OpenAccountFormFive>
        <OpenAccountFormSix v-if="page==6" @isFormOk="isFormOk" @form="pageSixForm" :chickShow="pageChickShow"></OpenAccountFormSix>
        <OpenAccountFormSeven v-if="page==7" @isFormOk="isFormOk" @form="pageSevenForm" :chickShow="pageChickShow"></OpenAccountFormSeven>
        <OpenAccountFormEight v-if="page==8" @isFormOk="isFormOk" @form="pageEightForm" :chickShow="pageChickShow"></OpenAccountFormEight>
        <OpenAccountFormNine v-if="page==9" @isFormOk="isFormOk" @form="pageNineForm" :chickShow="pageChickShow"></OpenAccountFormNine>
        <OpenAccountFormTen v-if="page==10" @isFormOk="isFormOk" @form="pageTenForm" :chickShow="pageChickShow"></OpenAccountFormTen>
        <OpenAccountFormEleven v-if="page==11" @isFormOk="isFormOk" @form="pageElevenForm" :chickShow="pageChickShow" :name="page11Name"></OpenAccountFormEleven>
        <OpenAccountFormTwelve v-if="page==12"></OpenAccountFormTwelve>
      </template>
      <template v-slot:backIcon>
          <div class="btnBack" v-show="btnBackShow" >
              <BtnSubmit color="red" @click="goBack()">{{btnBack}}</BtnSubmit>
          </div>
      </template>
      <template v-slot:nextIcon>
          <div class="btnNext" v-show="btnNextShow" >
              <BtnSubmit @click="goNext()">{{btnNext}}</BtnSubmit>
          </div>
      </template>
    </JoinUsFrame>
  </div>
</template>

<script>
import JoinUsFrame from '@/views/login/joinUs/components/joinUsFrame/JoinUsFrame'
import LeftBar from '@/views/login/joinUs/components/leftBar/LeftBar'
import BtnSubmit from '@/components/button/BtnSubmit'
import NumberNavBar from '@/views/login/joinUs/components/numberNavBar/NumberNavBar'
import OpenAccountFormOne from '@/views/login/openAccount/formOpenAccount/OpenAccountFormOne'
import OpenAccountFormTwo from '@/views/login/openAccount/formOpenAccount/OpenAccountFormTwo'
import OpenAccountFormThree from '@/views/login/openAccount/formOpenAccount/OpenAccountFormThree'
import OpenAccountFormFour from '@/views/login/openAccount/formOpenAccount/OpenAccountFormFour'
import OpenAccountFormFive from '@/views/login/openAccount/formOpenAccount/OpenAccountFormFive'
import OpenAccountFormSix from '@/views/login/openAccount/formOpenAccount/OpenAccountFormSix'
import OpenAccountFormSeven from '@/views/login/openAccount/formOpenAccount/OpenAccountFormSeven'
import OpenAccountFormEight from '@/views/login/openAccount/formOpenAccount/OpenAccountFormEight'
import OpenAccountFormNine from '@/views/login/openAccount/formOpenAccount/OpenAccountFormNine'
import OpenAccountFormTen from '@/views/login/openAccount/formOpenAccount/OpenAccountFormTen'
import OpenAccountFormEleven from '@/views/login/openAccount/formOpenAccount/OpenAccountFormEleven'
import OpenAccountFormTwelve from '@/views/login/openAccount/formOpenAccount/OpenAccountFormTwelve'
import {ProveImageController,OpenAccountPost} from "@/axios/api/dict"
export default {
  name:'OpenAccountApplyForm',
  components: {
    JoinUsFrame,
    LeftBar,
    BtnSubmit,
    NumberNavBar,
    OpenAccountFormOne,
    OpenAccountFormTwo,
    OpenAccountFormThree,
    OpenAccountFormFour,
    OpenAccountFormFive,
    OpenAccountFormSix,
    OpenAccountFormSeven,
    OpenAccountFormEight,
    OpenAccountFormNine,
    OpenAccountFormTen,
    OpenAccountFormEleven,
    OpenAccountFormTwelve,
  },
  data() {
    return {
      btnBack:'Back',
      btnNext:'Next',
      page:1,
      allPage:12,
      btnBackShow:false,
      btnNextShow:false,

      //LeftBar
      leftBarTittle:"帳戶類型",
      leftBarTextTwo:"提供虛假信息是非法行為，Finger可以隨時停止您的服務",

      //表單Warring顯示
      pageChickShow:false,

      //換頁面Boolen
      nextBoolen:false,

      //傳入11頁的名字
      page11Name:"aaa",

      postForm:{ //http://13.114.63.198:8989/api/user/ucenter/finger-account/openAccount
        accountType: 0,
        money_type: "",
        passwordQuestion1: "",
        passwordAnswer1: "",
        passwordQuestion2: "",
        passwordAnswer2:"",
        passwordQuestion3: "",
        passwordAnswer3:"",
        firstName: "",
        midName: "",
        lastName: "",
        birth: "",
        gender: false,
        city: "",
        address: "",
        zipCode: "",
        district: "",
        contactName: "",
        contactPhone: "",
        nationality: "",
        taxNationality: "",
        taxCity: "",
        taxNumber: "",
        isTaxDiscount: false,
        taxDisIdentityType: 0,
        taxDIsIdentityNumber: "",
        income: "",
        netCurrentAssets: "",
        netAsset: "",
        jobStatus: "",
        jobIndustry: "",
        jobCountry: "",
        jobCity: "",
        jobDistrict: "",
        jobZipCode: "",
        jobAddress: "",
        jobPosition: "",
        isShareholder: "",
        holderCode: "",
        financialCompany: "",
        isFinancial: "",
        financialPosition: "",
        marketCountry: "",
        marginExpYear: "",
        marginExpTradingVolume: "",
        marginExpKnowledge: "",
        stockBrokersId: "",
        futuresBrokersId: "",
        fundBrokersId: "",
        dcBrokersId: "",
        forexBrokersId: "",
        identityId: "",
        addressProofId: "",
        isSign: false,

        //form未有
        marital: 0,
        mobile: "Nun",
      },
      postForm2:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "",
        issuingAuthority:"Nun",
        periodValidity:"Nun",
        imageUrl: "",
        //issuingAuthority 發政機關(無)
        //periodValidity 有效期(無)
      },
      postForm3:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "Address",
        issuingAuthority:"Nun",
        periodValidity:"Nun",
        imageUrl: "",
        //fileType  (無)
        //issuingAuthority 發政機關(無)
        //periodValidity 有效期(無)
      },
    }
  },
  methods:{
      goBack(){
      if(this.page > 1){
        this.page--
      }
    },
    goNext(){
      if(this.nextBoolen == true && this.page == 10){
        ProveImageController(this.postForm2).then(res =>{
          if(res.code == 20000){
            this.postForm.identityId = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
        ProveImageController(this.postForm3).then(res =>{
          if(res.code == 20000){
            this.postForm.addressProofId = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
      }

      if(this.nextBoolen == true && this.page == 11){
        OpenAccountPost(this.postForm)
      }

      if(this.nextBoolen == true && this.page < this.allPage){
        this.page++
        this.nextBoolen = false
        this.pageChickShow = false
      }
      else{
        this.pageChickShow = true
      }
      console.log(this.postForm)
    },
    btnShow(){
      if(this.page <= 2){
        this.btnBackShow = false
        this.btnNextShow = false
      }
      else{
        if(this.page == 3){
          this.btnBackShow = false
          this.btnNextShow = true
          this.btnNext = "Next"
        }
        else if(this.page < this.allPage){
          this.btnBackShow = true
          this.btnNextShow = true
          this.btnNext = "Next"
        }
        else{
          this.btnNext = "Finish"
        }
      }
      
    },
    move(){
      if(this.page < 5){
        this.$refs.NumberNavBar.style.left = "0"
      }
      else if(this.page >= 5 && this.page < 9){
        this.$refs.NumberNavBar.style.left = "-"+ ((this.page - 4)*15.3) +"%"
      }
      else if(this.page >= 9){
        this.$refs.NumberNavBar.style.left = "-"+ (5*15.3) +"%"
      }
    },
    isFormOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    pageOneForm(form){
      this.postForm.accountType = form.accountType
      this.page = 2
    },
    pageTwoForm(form){
      this.page = form
    },
    pageThreeForm(form){
      this.postForm.money_type = form.dollarType
      this.postForm.passwordQuestion1 = form.question1
      this.postForm.passwordAnswer1 = form.anser1
      this.postForm.passwordQuestion2 = form.question2
      this.postForm.passwordAnswer2 = form.anser2
      this.postForm.passwordQuestion3 = form.question3
      this.postForm.passwordAnswer3 = form.anser3
    },
    pageFourForm(form){
      this.postForm.firstName = form.fristName
      this.postForm.midName = form.middleName
      this.postForm.lastName = form.lastName
      this.postForm.birth = form.dateBirth
      if(form.gender == "Male"){
        this.postForm.gender = true
      }
      else{
        this.postForm.gender = false
      }
      this.postForm.city = form.city
      this.postForm.address = form.contactAddress
      this.postForm.zipCode = form.zip
      this.postForm.district = form.district
      this.postForm.contactName = form.emergencyContactFristName + "_" + form.emergencyContactMiddleName + "_" + form.emergencyContactLastName
      this.postForm.contactPhone = form.emergencyContactNumberTittle + "_" + form.emergencyContactPhoneNumber
      this.postForm.nationality = form.country

      //傳入11頁的名字
      this.page11Name = form.fristName + "_" + form.middleName + "_" + form.lastName

      //API未有
      // numberTittle:"Select",
      // phoneNumber:"",
      // emergencyContactGender:"Select",
    },
    pageFiveForm(form){
      this.postForm.taxNationality = form.taxCountry
      this.postForm.taxCity = form.taxCity
      if(form.checkTexBoolen == true){
        this.postForm.taxNumber = form.USATex
      }
      else{
        this.postForm.taxNumber = form.abroadTex
      }
      this.postForm.isTaxDiscount = form.discountBoolen
      if(form.discountCity == "ID Card"){
        this.postForm.taxDisIdentityType = 0
      }
      else if(form.discountCity == "Passport"){
        this.postForm.taxDisIdentityType = 1
      }
      else{
        this.postForm.taxDisIdentityType = 2
      }
      this.postForm.taxDIsIdentityNumber = form.IdNumber

      // API未有
      // discountCountry:"Country",
    },
    pageSixForm(form){
      this.postForm.income = form.annualIncome
      this.postForm.netCurrentAssets = form.netCurrentAssets
      this.postForm.netAsset = form.netAssets
      this.postForm.jobStatus = form.employmentStatus
      this.postForm.jobIndustry = form.companyIndustry
      this.postForm.jobCountry = form.country
      this.postForm.jobCity = form.city
      this.postForm.jobDistrict = form.district
      this.postForm.jobZipCode = form.zip
      this.postForm.jobAddress = form.contactAddress
      this.postForm.jobPosition = form.jobs

      // API未有
      // dollarType:"DollarType",
    },
    pageSevenForm(form){
      if(form.Active1 == true){
        this.postForm.isShareholder = "Yes"
      }
      else{
        this.postForm.isShareholder = "No"
      }
      this.postForm.holderCode = form.companyStockCode
      if(form.Active2 == true){
        this.postForm.isFinancial = "Yes"
      }
      else{
        this.postForm.isFinancial = "No"
      }
      this.postForm.financialCompany = form.companyName
      this.postForm.financialPosition = form.post
    },
    pageEightForm(form){
      this.postForm.marketCountry = form.marketCountry
      this.postForm.marginExpYear = form.marginType1
      this.postForm.marginExpTradingVolume = form.marginType2
      this.postForm.marginExpKnowledge = form.marginType3

      // API未有
      // bond1:"Select",
      // bond2:"Select",
      // bond3:"Select",
    },
    pageNineForm(form){
      this.postForm.stockBrokersId = form.securitiesCategoryCountry + "_" + form.securitiesCategoryBorker
      this.postForm.fundBrokersId = form.fundCategoryCountry + "_" + form.fundCategoryBorker
      this.postForm.futuresBrokersId = form.futuresCategoryCountry + "_" + form.futuresCategoryBorker
      this.postForm.dcBrokersId = form.electronicMoneyCountry + "_" + form.electronicMoneyBorker
      this.postForm.forexBrokersId = form.foreignExchangeCountry + "_" + form.foreignExchangeBorker
    },
    pageTenForm(form){
      this.postForm2.fileCountry = form.country
      this.postForm2.fileType = form.city
      this.postForm2.imageUrl = form.imgUrl1
      this.postForm3.fileCountry = form.country2
      this.postForm3.imageUrl = form.imgUrl2

      // API未有
      // IdCardNumber:"",
      // city2:"Select",
      // district:"",
      // zip:"",
      // contactAddress:"",
    },
    pageElevenForm(form){
      if(form.name == this.page11Name){
        this.postForm.isSign = true
      }
      else{
        this.postForm.isSign = false
      }
    }
  },
  mounted(){
    this.btnShow()
  },
  watch:{
    "page"(){
      this.btnShow()
      this.move()
      // this.navBarShow()
      if(this.page == 1){
        this.leftBarTittle = "帳戶類型"
        this.leftBarTextTwo = "提供虛假信息是非法行為，Finger可以隨時停止您的服務"
      }
      else if(this.page == 2){
        this.leftBarTittle = "個人資訊"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else if(this.page == 3){
        this.leftBarTittle = "機構訊息"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else if(this.page == 4){
        this.leftBarTittle = "牌照信息"
        this.leftBarTextTwo = "如果想要介绍交易期货或期货期权的账户，您必须是注册期货佣金商或介绍经纪商。如果想要介绍交易现金外汇的账户，您必须是注册期货佣金商、介绍经纪商、零售外汇交易商或证券经纪交易商。"
      }
      else if(this.page == 5){
        this.leftBarTittle = "資格證書"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else{
        this.leftBarTittle = "個人資訊"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
    }
  }
}
</script>

<style scoped>
    .openAccountApply{
        width: 100%;
        height: 100%;
        background: rgb(255, 255, 255);
    }
  .btnBack{
    position: absolute;
    left: 210px;
    top: 0;
  }
  .btnNext{
    position: absolute;
    right: 210px;
    top: 0;
  }
  .pageForm{
    width: 100%;
    height: 100%;
  }

  /*LeftBar*/
  .divBox{
    width: 100%;
    height: 100%;
    /* border: 1px rgb(102, 255, 0) solid; */
    display: flex;
    
  }
  .centerLine{
    height: 100%;
    /* border: 1px rgb(102, 255, 0) solid; */
    position: relative;
  }
  .span{
    font-family: Inter;
    font-style: normal;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 40px;
  }
  .span:nth-child(1){
    font-size: 72px;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 36px;
  }
  .span:nth-child(2){
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 36px;
    max-width: 300px;
  }
  .span:nth-child(3){
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 255px;
  }
  .span:nth-child(3):hover{
    color: #0099ff;
    font-size: 20px;
  }
  .span:nth-child(4),.span:nth-child(5),.span:nth-child(6){
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .span:nth-child(4):hover,.span:nth-child(5):hover,.span:nth-child(6):hover{
    color: #0099ff;
    font-size: 20px;
  }
  .NumberNavBarHiddenBox{
    height: 100%;
    display: flex;
  }
  .NumberNavBarHidden{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }
  .NumberNavBar{
    position: absolute;
    top: 0;
    left: 0;
    transition: .5s;
  }
</style>
