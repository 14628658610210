<template>
  <div class="openAccountFormOne">
    <div class="textBigBox">
        <div class="tittleText">
          <span>請選擇適合您的帳號類型</span>
        </div>
        <div class="tittleText">
          <span>請從以下身分中挑選您需要的服務，若是您不確定身分</span>
          <span>服務中心</span>
        </div>
        <div class="drowDownMeunBox">
            <DrowDownMeun :meunHeight="194" titleName="個人賬戶" :down="DrowDown1" @upDown="upDown1" backgroundColor="#FF1057" color="#FFFFFF">
                <template v-slot:meun>
                    <div class="itemBox" @click="btn1">
                        <div class="tittleName">
                            <span>個人賬戶</span>
                        </div>
                        <div class="insideText">
                            <span>由一名賬戶持有人所有的投資賬戶</span>
                        </div>
                    </div>
                    <div class="itemBox" @click="btn2">
                        <div class="tittleName">
                            <span>聯名賬戶</span>
                        </div>
                        <div class="insideText">
                            <span>由多人持有的投資賬戶</span>
                        </div>
                    </div>
                </template>
            </DrowDownMeun>
        </div>
        <div class="drowDownMeunBox">
            <DrowDownMeun :meunHeight="285" titleName="機構賬戶" :down="DrowDown2" @upDown="upDown2" backgroundColor="#FF1057" color="#FFFFFF">
                <template v-slot:meun>
                    <div class="gridLow">
                        <div class="gridLowItem">
                            <div class="itemBox">
                                <div class="tittleName">
                                    <span>經紀商</span>
                                </div>
                                <div class="insideText">
                                    <span>一般個人使用的投資賬戶</span>
                                </div>
                            </div>
                        </div>
                        <div class="gridLowItem">
                            <div class="itemBox">
                                <div class="tittleName">
                                    <span>小型公司</span>
                                </div>
                                <div class="insideText">
                                    <span>一般個人使用的投資賬戶</span>
                                </div>
                            </div>
                        </div>
                        <div class="gridLowItem">
                            <div class="itemBox">
                                <div class="tittleName">
                                    <span>自營交易集團</span>
                                </div>
                                <div class="insideText">
                                    <span>一般個人使用的投資賬戶</span>
                                </div>
                            </div>
                        </div>
                        <div class="gridLowItem">
                            <div class="itemBox">
                                <div class="tittleName">
                                    <span>對沖基金</span>
                                </div>
                                <div class="insideText">
                                    <span>一般個人使用的投資賬戶</span>
                                </div>
                            </div>
                        </div>
                        <div class="gridLowItem">
                            <div class="itemBox">
                                <div class="tittleName">
                                    <span>資金管理人</span>
                                </div>
                                <div class="insideText">
                                    <span>一般個人使用的投資賬戶</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </DrowDownMeun>
        </div>
        <div class="drowDownMeunBox">
            <DrowDownMeun :meunHeight="194" titleName="合規賬戶" :down="DrowDown3" @upDown="upDown3" backgroundColor="#FF1057" color="#FFFFFF">
                <template v-slot:meun>
                    <div class="itemBox">
                        <div class="tittleName">
                            <span>合規官員</span>
                        </div>
                        <div class="insideText">
                            <span>需要監控部分交易的官員</span>
                        </div>
                    </div>
                    <div class="itemBox">
                        <div class="tittleName">
                            <span>管理員</span>
                        </div>
                        <div class="insideText">
                            <span>多人聯名賬戶</span>
                        </div>
                    </div>
                </template>
            </DrowDownMeun>
        </div>
    </div>
  </div>
</template>

<script>
import DrowDownMeun from '@/components/XuComponents/drowDownMeun/DrowDownMeun'
export default {
  name:'OpenAccountFormOne',
  components: {
      DrowDownMeun
  },
  data() {
    return {
        DrowDown1:true,
        DrowDown2:false,
        DrowDown3:false,

        form:{
            accountType:Number
        }
    }
  },
  methods:{
      upDown1(Boolean){
        if(Boolean == true){
            this.DrowDown1 = true
            this.DrowDown2 = false
            this.DrowDown3 = false
        }
      },
      upDown2(Boolean){
        if(Boolean == true){
            this.DrowDown1 = false
            this.DrowDown2 = true
            this.DrowDown3 = false
        }
      },
      upDown3(Boolean){
        if(Boolean == true){
            this.DrowDown1 = false
            this.DrowDown2 = false
            this.DrowDown3 = true
        }
      },
      btn1(){
        this.form.accountType = 1
        this.$emit('form', this.form)
      },
  },
  watch:{

  }
}
</script>

<style scoped>
.openAccountFormOne{
    width: 100%;
    height: 100%;
    display: flex;
}
.textBigBox{
    height: 100%;
    margin: 0 auto;
    /* border: 1px red solid; */
}
.tittleText{
    margin: 0 auto;
    font-family: Inter;
    font-style: normal;
    text-align: center;
}
.tittleText:nth-child(1){
    padding-top: 50px;
    font-size: 64px;
    font-weight: 500;
    line-height: 54px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}
.tittleText:nth-child(2){
    font-size: 24px;
    font-weight: 600;
    line-height: 54px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}
.tittleText:nth-child(2) span:nth-child(2){
    cursor: pointer;
    color: #2F43F4;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: .5;
}
.tittleText:nth-child(2) span:nth-child(2):hover{
    color: #ff31a2;
    transition: .5;
}
.drowDownMeunBox{
    max-width: 898px;
    /* border: 1px red solid; */
    margin-bottom: 10px;
}
.itemBox{
    width: 100%;
    border: 2px solid #E5E5E5;
    box-sizing: border-box;
    cursor: pointer;
    transition: .5s;
}
.itemBox:hover{
    background-color: rgb(0, 153, 255);
    color: #FFF;
    transition: .5s;
}
.itemBox:nth-child(1){
    border-bottom: none;
}
.tittleName{
    font-family: Roboto;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;
    margin: 17px 0;
    margin-bottom: 0px;
    padding-bottom: 3px;
}
.insideText{
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    /* margin-bottom: 17px; */
    padding-bottom: 17px;
}
.gridLow{
    width: 100%;
    height: 285px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}
.gridLowItem{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
}
.gridLowItem .itemBox{
    border: none;
    padding-top: 1px;
}
.gridLowItem:nth-child(1){
    grid-column: 1/3;
    grid-row: 1/2;
}
.gridLowItem:nth-child(2){
    grid-column: 1/2;
    grid-row: 2/3;
}
.gridLowItem:nth-child(3){
    grid-column: 2/3;
    grid-row: 2/3;
}
.gridLowItem:nth-child(4){
    grid-column: 1/2;
    grid-row: 3/4;
}
.gridLowItem:nth-child(5){
    grid-column: 2/3;
    grid-row: 3/4;
}
</style>
