<template>
  <div class="openAccountFormTwo">
    <div class="textBigBox">
        <div class="itemsBox">
            <div class="spanBox">
                <span>监管及许可信息聲明</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="spanBox">
                <span>根據偽証罪處罰條例，本人聲明本人已審察表格中的訊息，據本人所知及信其真實，準確且完整。根據偽証罪處罰條款，本人進一步證明:</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgBlack">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>本人是本表格相關之收入的收益所有人(或被授權為收益所有人簽字)，或正在使用表格作為文件證明本人是一家國外金融機構的所有人或帳戶持有人。</span>
            </div>
        </div>
        <div  class="itemsBox">
            <div class="svgBlack">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>表格第一行內填寫的人士並非美國人</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgBlack">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>本表格相關收入:</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgWit">
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 11C8.53754 11 11 8.53757 11 5.5C11 2.46243 8.53754 0 5.5 0C2.46246 0 0 2.46243 0 5.5C0 8.53757 2.46246 11 5.5 11ZM5.5 10C7.98529 10 10 7.98529 10 5.5C10 3.01471 7.98529 1 5.5 1C3.01471 1 1 3.01471 1 5.5C1 7.98529 3.01471 10 5.5 10Z" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>與再美國的貿易或商業活動無關。</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgWit">
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 11C8.53754 11 11 8.53757 11 5.5C11 2.46243 8.53754 0 5.5 0C2.46246 0 0 2.46243 0 5.5C0 8.53757 2.46246 11 5.5 11ZM5.5 10C7.98529 10 10 7.98529 10 5.5C10 3.01471 7.98529 1 5.5 1C3.01471 1 1 3.01471 1 5.5C1 7.98529 3.01471 10 5.5 10Z" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>雖為相關收入，但根據所得稅協定不需要交納稅費，或者</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgWit">
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 11C8.53754 11 11 8.53757 11 5.5C11 2.46243 8.53754 0 5.5 0C2.46246 0 0 2.46243 0 5.5C0 8.53757 2.46246 11 5.5 11ZM5.5 10C7.98529 10 10 7.98529 10 5.5C10 3.01471 7.98529 1 5.5 1C3.01471 1 1 3.01471 1 5.5C1 7.98529 3.01471 10 5.5 10Z" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>為合夥企業相關收入的合夥人收入。</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgBlack">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>該表格第一行填寫的人士是表格第九行(如有)所列協定國的居民，屬於美國與該國之達成的所得稅</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgBlack">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>對於經紀交易或易貨交易，受益所有人為國稅局(IRS)W-8BEN表格說明中定義的豁免外籍人士。</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="svgBlack">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="black"/>
                </svg>
            </div>
            <div class="spanBox">
                <span>本人同意，如果此表格內証明訊息不在準確，本人會在三十天內提交一份新的表格。</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="spanBox">
                <span>本人同意，上述每一項聲明準確無誤，如有不實受偽証罪處罰;且本人確定已閱讀W-8BEN表格內的訊息(包括任何預先填寫的訊息)，訊息均準確無誤。</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="btnBox" @click="TwoToOneBtn11">
                <TwoToOneBtn :Active="boolean1" titleName="是"></TwoToOneBtn>
            </div>
            <div class="btnBox" @click="TwoToOneBtn12">
                <TwoToOneBtn :Active="!boolean1" titleName="否"></TwoToOneBtn>
            </div>
        </div>
        <div class="itemsBox">
            <div class="spanBox">
                <span>此外，本人授權將此表格提供給任何具備本人名下收入監管，接收或保管權的稅務機構，或可向本收益人實際支付檢退稅的機構。</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="btnBox" @click="TwoToOneBtn21">
                <TwoToOneBtn :Active="boolean2" titleName="是"></TwoToOneBtn>
            </div>
            <div class="btnBox" @click="TwoToOneBtn22">
                <TwoToOneBtn :Active="!boolean2" titleName="否"></TwoToOneBtn>
            </div>
        </div>
        <div class="itemsBox">
            <div class="spanBox">
                <span>除了用已確立您非美國人身分及降低預扣稅率的聲明外，美國國稅務局不需要徵得您對本文件條款的同意。</span>
            </div>
        </div>
        <div class="itemsBox">
            <div class="spanBox">
                <span>本人同意所有稅務表格均用過電子形式而非紙質形式收集或發送，包括通過帳戶管理收集和發送。</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import TwoToOneBtn from '@/components/XuComponents/btn/TwoToOneBtn'
export default {
  name:'OpenAccountFormTwo',
  components: {
      TwoToOneBtn
  },
  data() {
    return {
        boolean1:false,
        boolean2:false,
    }
  },
  methods:{
      TwoToOneBtn11(){
          this.boolean1 = true
      },
      TwoToOneBtn12(){
          this.boolean1 = false
      },
      TwoToOneBtn21(){
          this.boolean2 = true
      },
      TwoToOneBtn22(){
          this.boolean2 = false
      },
      exportForm(){
          if(this.boolean1 == true 
          && this.boolean2 == true){
              const page = 3
              this.$emit('form', page)
          }
      }
  },
  watch:{
      "boolean1"(){
          this.exportForm()
      },
      "boolean2"(){
          this.exportForm()
      }
  }
}
</script>

<style scoped>
.openAccountFormTwo{
    width: 100%;
    height: 100%;
    display: flex;
}
.textBigBox{
    height: 100%;
    margin: 0 auto;
    /* border: 1px red solid; */
    max-width: 900px;
}
.itemsBox{
    display: flex;
    margin-bottom: 4px;
}
.spanBox{
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.itemsBox:nth-child(1){
    padding-top: 50px;
    margin-bottom: 21px;
}
.itemsBox:nth-child(1) .spanBox{
    font-family: Inter;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.itemsBox:nth-child(2){
    margin-bottom: 45px;
}
.itemsBox:nth-child(2) .spanBox{
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.itemsBox:nth-child(5) .spanBox{
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.itemsBox:nth-child(12){
    max-width: 640px;
}
.svgBlack{
    margin-right: 14px;
}
.svgWit{
    margin: 0 14px;
}
.btnBox{
    width: 50px;
    height: 30px;
    margin: 0 50px;
}
</style>
