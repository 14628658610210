<template>
  <div class="openAccountFormThree">
    <div class="textBigBox">
        <div class="itemBox">
            <div class="itemTittleBox">
                <div class="center">
                    <span>結算貨幣</span>
                </div>
            </div>
        </div>
        <div class="itemBox">
            <div class="inputBox">
                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.dollarType" :show="chickShow" @isCheckOkValue="dollarTypeCheck">
                    <select-menu :title="form.dollarType" :width='600'>
                        <option-menu v-for="item in dollarTypeList" :key="item.id" @click="dollarType(item)">{{item.enName}}</option-menu>
                    </select-menu>
                </Validator>
            </div>
        </div>
        <div class="itemBox">
            <div class="itemTittleBox">
                <div class="center">
                    <span>安全問題</span>
                </div>
            </div>
        </div>
        <div class="itemBox">
            <div class="inputBox">
                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.question1" :show="chickShow" @isCheckOkValue="question1Check">
                    <select-menu :title="form.question1" :width='600'>
                        <option-menu v-for="item in question1List" :key="item.id" @click="question1(item)">{{item.enName}}</option-menu>
                    </select-menu>
                </Validator>
            </div>
        </div>
        <div class="itemBox">
            <span>答案一</span>
        </div>
        <div class="itemBox">
            <Validator type="text" :value="form.anser1" :show="chickShow" @isCheckOkValue="anser1Check">
            <input-text placeholder="Ans" v-model="form.anser1"/>
            </Validator>
        </div>
        <div class="itemBox">
            <div class="inputBox">
                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.question2" :show="chickShow" @isCheckOkValue="question2Check">
                    <select-menu :title="form.question2" :width='600'>
                        <option-menu v-for="item in question2List" :key="item.id" @click="question2(item)">{{item.enName}}</option-menu>
                    </select-menu>
                </Validator>
            </div>
        </div>
        <div class="itemBox">
            <span>答案二</span>
        </div>
        <div class="itemBox">
            <Validator type="text" :value="form.anser2" :show="chickShow" @isCheckOkValue="anser2Check">
            <input-text placeholder="Ans" v-model="form.anser2"/>
            </Validator>
        </div>
        <div class="itemBox">
            <div class="inputBox">
                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.question3" :show="chickShow" @isCheckOkValue="question3Check">
                <select-menu :title="form.question3" :width='600'>
                    <option-menu v-for="item in question3List" :key="item.id" @click="question3(item)">{{item.enName}}</option-menu>
                </select-menu>
                </Validator>
            </div>
        </div>
        <div class="itemBox">
            <span>答案三</span>
        </div>
        <div class="itemBox">
            <Validator type="text" :value="form.anser3" :show="chickShow" @isCheckOkValue="anser3Check">
            <input-text placeholder="Ans" v-model="form.anser3"/>
            </Validator>
        </div>
    </div>
  </div>
</template>

<script>
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import InputText from '@/components/XuComponents/form/InputText'
import {getDictById} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormThree',
  components: {
      SelectMenu,
      OptionMenu,
      InputText,
      Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
        dollarTypeList:[],
        question1List:[],
        question2List:[],
        question3List:[],

        isFormOk:false,

        form:{
            dollarType:"Select",
            question1:"Select",
            anser1:"",
            question2:"Select",
            anser2:"",
            question3:"Select",
            anser3:"",
        },
        isCheckAllOkForm:{
            companyNameCheck:false,
            question1Check:false,
            anser1Check:false,
            question2Check:false,
            anser2Check:false,
            question3Check:false,
            anser3Check:false,
        }
    }
  },
  methods:{
      dollarType(item){
          this.form.dollarType = item.enName
      },
      question1(item){
          this.form.question1 = item.enName
      },
      question2(item){
          this.form.question2 = item.enName
      },
      question3(item){
          this.form.question3 = item.enName
      },
      get(){
        getDictById(18000).then(res=>{
            this.dollarTypeList = res.data.list
        })
        getDictById(20000).then(res=>{
            this.question1List = res.data.list
            this.question2List = res.data.list
            this.question3List = res.data.list
        })
      },
    dollarTypeCheck(CheckBoolen){
        this.isCheckAllOkForm.dollarTypeCheck = CheckBoolen
        this.changPage()
    },
    question1Check(CheckBoolen){
        this.isCheckAllOkForm.question1Check = CheckBoolen
        this.changPage()
    },
    anser1Check(CheckBoolen){
        this.isCheckAllOkForm.anser1Check = CheckBoolen
        this.changPage()
    },
    question2Check(CheckBoolen){
        this.isCheckAllOkForm.question2Check = CheckBoolen
        this.changPage()
    },
    anser2Check(CheckBoolen){
        this.isCheckAllOkForm.anser2Check = CheckBoolen
        this.changPage()
    },
    question3Check(CheckBoolen){
        this.isCheckAllOkForm.question3Check = CheckBoolen
        this.changPage()
    },
    anser3Check(CheckBoolen){
        this.isCheckAllOkForm.anser3Check = CheckBoolen
        this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.dollarTypeCheck == true 
      && this.isCheckAllOkForm.question1Check == true 
      && this.isCheckAllOkForm.anser1Check == true 
      && this.isCheckAllOkForm.question2Check == true 
      && this.isCheckAllOkForm.anser2Check == true 
      && this.isCheckAllOkForm.question3Check == true 
      && this.isCheckAllOkForm.anser3Check == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
      this.get()
  }
}
</script>

<style scoped>
.openAccountFormThree{
    width: 100%;
    height: 100%;
    display: flex;
}
.textBigBox{
    height: 100%;
    margin: 0 auto;
    width: 600px;
    max-width: 600px;
}
.itemTittleBox{
    width: 100%;
    height: 40px;
    background: #333333;
    border-radius: 9px;
    display: flex;
}
.center{
    height: 100%;
    margin: 0 auto;
    width: 90%;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: #FBFBFB;
}
.itemBox:nth-child(1){
    margin-bottom: 24px;
    padding-top: 22px;
    
}
.itemBox:nth-child(2){
    margin-bottom: 20px;
}
.itemBox:nth-child(3){
    margin-bottom: 24px;
}
.itemBox:nth-child(5) span, .itemBox:nth-child(8) span, .itemBox:nth-child(11) span{
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
</style>
