<template>
  <div class="openAccountFormNine">
    <div class="textBigBox">
      <div class="itemBox">
        <div class="itemTittleBox">
          <div class="center">
            <span>券商信息</span>
          </div>
        </div>
      </div>
      <div class="textBox">
        <div class="textCenter">
          <div class="gridLow">
            <div class="gridLowItem">
              <div class="textTittle">
                <span>證券類別</span>
                <div class="icon"></div>
              </div>
              <div class="itemsBox">
                <div class="drowMeunBox">
                  <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.securitiesCategoryCountry" :show="chickShow" @isCheckOkValue="securitiesCategoryCountryCheck">
                    <select-menu :title="form.securitiesCategoryCountry" :width='137'>
                      <option-menu v-for="item in securitiesCategoryCountryList" :key="item.id" @click="securitiesCategoryCountry(item)">{{item.name}}</option-menu>
                    </select-menu>
                  </Validator>
                </div>
                <div class="inputBox">
                  <Validator :value="form.securitiesCategoryBorker" :show="chickShow" @isCheckOkValue="securitiesCategoryBorkerCheck">
                  <input-text placeholder="Borker" v-model="form.securitiesCategoryBorker"/>
                  </Validator>
                </div>
              </div>
            </div>
            <div class="gridLowItem">
              <div class="textTittle">
                <span>基金類別</span>
                <div class="icon"></div>
              </div>
              <div class="itemsBox">
                <div class="drowMeunBox">
                  <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.fundCategoryCountry" :show="chickShow" @isCheckOkValue="fundCategoryCountryCheck">
                  <select-menu :title="form.fundCategoryCountry" :width='137'>
                    <option-menu v-for="item in fundCategoryCountryList" :key="item.id" @click="fundCategoryCountry(item)">{{item.name}}</option-menu>
                  </select-menu>
                  </Validator>
                </div>
                <div class="inputBox">
                  <Validator :value="form.fundCategoryBorker" :show="chickShow" @isCheckOkValue="fundCategoryBorkerCheck">
                  <input-text placeholder="Borker" v-model="form.fundCategoryBorker"/>
                  </Validator>
                </div>
              </div>
            </div>
            <div class="gridLowItem">
              <div class="textTittle">
                <span>期貨類別</span>
                <div class="icon"></div>
              </div>
              <div class="itemsBox">
                <div class="drowMeunBox">
                  <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.futuresCategoryCountry" :show="chickShow" @isCheckOkValue="futuresCategoryCountryCheck">
                  <select-menu :title="form.futuresCategoryCountry" :width='137'>
                    <option-menu v-for="item in futuresCategoryCountryList" :key="item.id" @click="futuresCategoryCountry(item)">{{item.name}}</option-menu>
                  </select-menu>
                  </Validator>
                </div>
                <div class="inputBox">
                  <Validator :value="form.futuresCategoryBorker" :show="chickShow" @isCheckOkValue="futuresCategoryBorkerCheck">
                  <input-text placeholder="Borker" v-model="form.futuresCategoryBorker"/>
                  </Validator>
                </div>
              </div>
            </div>
            <div class="gridLowItem">
              <div class="textTittle">
                <span>電子貨幣</span>
                <div class="icon"></div>
              </div>
              <div class="itemsBox">
                <div class="drowMeunBox">
                  <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.electronicMoneyCountry" :show="chickShow" @isCheckOkValue="electronicMoneyCountryCheck">
                  <select-menu :title="form.electronicMoneyCountry" :width='137'>
                    <option-menu v-for="item in electronicMoneyCountryList" :key="item.id" @click="electronicMoneyCountry(item)">{{item.name}}</option-menu>
                  </select-menu>
                  </Validator>
                </div>
                <div class="inputBox">
                  <Validator :value="form.electronicMoneyBorker" :show="chickShow" @isCheckOkValue="electronicMoneyBorkerCheck">
                  <input-text placeholder="Borker" v-model="form.electronicMoneyBorker"/>
                  </Validator>
                </div>
              </div>
            </div>
            <div class="gridLowItem">
              <div class="textTittle">
                <span>外匯類別</span>
                <div class="icon"></div>
              </div>
              <div class="itemsBox">
                <div class="drowMeunBox">
                  <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.foreignExchangeCountry" :show="chickShow" @isCheckOkValue="foreignExchangeCountryCheck">
                  <select-menu :title="form.foreignExchangeCountry" :width='137'>
                    <option-menu v-for="item in foreignExchangeCountryList" :key="item.id" @click="foreignExchangeCountry(item)">{{item.name}}</option-menu>
                  </select-menu>
                  </Validator>
                </div>
                <div class="inputBox">
                  <Validator :value="form.foreignExchangeBorker" :show="chickShow" @isCheckOkValue="foreignExchangeBorkerCheck">
                    <input-text placeholder="Borker" v-model="form.foreignExchangeBorker"/>
                  </Validator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/XuComponents/form/InputText'
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import {CountryList} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormNine',
  components: {
    InputText,
    SelectMenu,
    OptionMenu,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      securitiesCategoryCountryList:[],
      fundCategoryCountryList:[],
      futuresCategoryCountryList:[],
      electronicMoneyCountryList:[],
      foreignExchangeCountryList:[],
      form:{
        securitiesCategoryCountry:"Select",
        securitiesCategoryBorker:"",
        fundCategoryCountry:"Select",
        fundCategoryBorker:"",
        futuresCategoryCountry:"Select",
        futuresCategoryBorker:"",
        electronicMoneyCountry:"Select",
        electronicMoneyBorker:"",
        foreignExchangeCountry:"Select",
        foreignExchangeBorker:"",
      },
      isCheckAllOkForm:{}
    }
  },
  methods:{
    securitiesCategoryCountry(item){
      this.form.securitiesCategoryCountry = item.name
    },
    fundCategoryCountry(item){
      this.form.fundCategoryCountry = item.name
    },
    futuresCategoryCountry(item){
      this.form.futuresCategoryCountry = item.name
    },
    electronicMoneyCountry(item){
      this.form.electronicMoneyCountry = item.name
    },
    foreignExchangeCountry(item){
      this.form.foreignExchangeCountry = item.name
    },
    get(){
      CountryList().then(res =>{
        this.securitiesCategoryCountryList = res.data.items
        this.fundCategoryCountryList = res.data.items
        this.futuresCategoryCountryList = res.data.items
        this.electronicMoneyCountryList = res.data.items
        this.foreignExchangeCountryList = res.data.items
      })
    },
    securitiesCategoryCountryCheck(CheckBoolen){
      this.isCheckAllOkForm.securitiesCategoryCountryCheck = CheckBoolen
      this.changPage()
    },
    securitiesCategoryBorkerCheck(CheckBoolen){
      this.isCheckAllOkForm.securitiesCategoryBorkerCheck = CheckBoolen
      this.changPage()
    },
    fundCategoryCountryCheck(CheckBoolen){
      this.isCheckAllOkForm.fundCategoryCountryCheck = CheckBoolen
      this.changPage()
    },
    fundCategoryBorkerCheck(CheckBoolen){
      this.isCheckAllOkForm.fundCategoryBorkerCheck = CheckBoolen
      this.changPage()
    },
    futuresCategoryCountryCheck(CheckBoolen){
      this.isCheckAllOkForm.futuresCategoryCountryCheck = CheckBoolen
      this.changPage()
    },
    futuresCategoryBorkerCheck(CheckBoolen){
      this.isCheckAllOkForm.futuresCategoryBorkerCheck = CheckBoolen
      this.changPage()
    },
    electronicMoneyCountryCheck(CheckBoolen){
      this.isCheckAllOkForm.electronicMoneyCountryCheck = CheckBoolen
      this.changPage()
    },
    electronicMoneyBorkerCheck(CheckBoolen){
      this.isCheckAllOkForm.electronicMoneyBorkerCheck = CheckBoolen
      this.changPage()
    },
    foreignExchangeCountryCheck(CheckBoolen){
      this.isCheckAllOkForm.foreignExchangeCountryCheck = CheckBoolen
      this.changPage()
    },
    foreignExchangeBorkerCheck(CheckBoolen){
      this.isCheckAllOkForm.foreignExchangeBorkerCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.securitiesCategoryCountryCheck == true 
      && this.isCheckAllOkForm.securitiesCategoryBorkerCheck == true 
      && this.isCheckAllOkForm.fundCategoryCountryCheck == true 
      && this.isCheckAllOkForm.fundCategoryBorkerCheck == true 
      && this.isCheckAllOkForm.futuresCategoryCountryCheck == true 
      && this.isCheckAllOkForm.futuresCategoryBorkerCheck == true 
      && this.isCheckAllOkForm.electronicMoneyCountryCheck == true 
      && this.isCheckAllOkForm.electronicMoneyBorkerCheck == true 
      && this.isCheckAllOkForm.foreignExchangeCountryCheck == true 
      && this.isCheckAllOkForm.foreignExchangeBorkerCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
    this.get()
  }
}
</script>

<style scoped>
.openAccountFormNine{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  width: 861px;
  max-width: 861px;
}
.itemTittleBox{
  width: 100%;
  height: 40px;
  background: #333333;
  border-radius: 9px;
  display: flex;
}
.center{
  height: 100%;
  margin: 0 auto;
  width: 90%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #FBFBFB;
}
.itemBox{
  padding-top: 40px;
  margin-bottom: 20px;
}
.textBox{
  width: 100%;
  height: calc(100% - 100px);
  
  display: flex;
}
.textCenter{
  height: 100%;
  margin: 0 auto;
}
.gridLow{
  width: 800px;
  height: 303px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
.gridLowItem{
  width: 100%;
  height: 100%;
}
.textTittle{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
}
.icon{
  width: 20px;
  height: 20px;
  background: black;
  border-radius: 20px;
  margin-top: 4px;
  margin-left: 5px;
}
.itemsBox{
  width: 100%;
  display: flex;
}
.drowMeunBox{
  margin-right: 20px;
}
</style>
