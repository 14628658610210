<template>
  <div class="openAccountFormTen">
    <div class="textBigBox">
      <div class="itemBox">
        <div class="itemTittleBox">
          <div class="center">
            <span>身份證明</span>
          </div>
        </div>
      </div>
      <div class="textBox">
        <span>請從以下選擇中挑選一種文件上傳，並確保圖片為清晰且無缺損</span>
      </div>
      <div class="iconsBox">
        <div class="gridItems">
          <div class="dorwDownBox">
            <div class="dorwDown">
              <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.country" :show="chickShow" @isCheckOkValue="countryCheck">
              <select-menu :title="form.country" :width='137'>
                <option-menu v-for="item in countryList" :key="item.id" @click="country(item)">{{item.name}}</option-menu>
              </select-menu>
              </Validator>
            </div>
            <div>
              <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.city" :show="chickShow" @isCheckOkValue="cityCheck">
              <select-menu :title="form.city" :width='137'>
                <option-menu v-for="item in cityList" :key="item.id" @click="city(item)">{{item.enName}}</option-menu>
              </select-menu>
              </Validator>
            </div>
          </div>
          <div class="inputBox">
            <div class="itemTittleBox2">
              <span>證件號碼</span>
            </div>
            <Validator :value="form.IdCardNumber" :show="chickShow" @isCheckOkValue="IdCardNumberCheck">
            <input-text placeholder="VA" v-model="form.IdCardNumber"/>
            </Validator>
          </div>
        </div>
        <div class="gridItems">
          <div class="fileUpLoadBox">
            <el-upload
              :on-success="onUploadSuccessImg"
              :before-upload="beforeAvatarUpload"
              :multiple="false"
              :action="avatarUrl"
              :data="{module:'idCard'}"
              :limit="1"
              class="uploadbtn">
              <ElUploadBtnStyle>
                <template v-slot:img>
                  <img src="@/assets/xuImg/idCard.png" v-if="!imgUrlBoolen1">
                  <img :src="form.imgUrl1" v-if="imgUrlBoolen1">
                </template>
              </ElUploadBtnStyle>
            </el-upload>
          </div>
          <div class="warringText" v-if="chickShow">
            <span>{{imgwarring1}}</span>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="itemTittleBox">
          <div class="center">
            <span>地址证明</span>
          </div>
        </div>
      </div>
      <div class="textBox">
        <span>地址證明文件可以是您之前輸入地址所收到的水電費對賬單，或者具有您地址的銀行對賬單以及其他政府或銀行出具的包含您地址和姓名的證明文件</span>
      </div>
      <div class="iconsBox">
        <div class="gridItems">
          <div class="iconsBox2">
            <div class="gridItems">
              <div class="itemTittleBox2">
                <span>Country</span>
              </div>
              <div class="dorwDown2">
                <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.country2" :show="chickShow" @isCheckOkValue="country2Check">
                <select-menu :title="form.country2" :width='110'>
                  <option-menu v-for="item in countryList2" :key="item.id" @click="country2(item)">{{item.name}}</option-menu>
                </select-menu>
                </Validator>
              </div>
            </div>
            <div class="gridItems">
              <div class="itemTittleBox2">
                <span>City</span>
              </div>
              <div class="dorwDown2">
                <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.city2" :show="chickShow" @isCheckOkValue="city2Check">
                <select-menu :title="form.city2" :width='110'>
                  <option-menu v-for="item in cityList2" :key="item.id" @click="city2(item)">{{item.name}}</option-menu>
                </select-menu>
                </Validator>
              </div>
            </div>
            <div class="gridItems">
              <div class="itemTittleBox2">
                <span>District</span>
              </div>
              <div class="dorwDown2">
                <Validator :value="form.district" :show="chickShow" @isCheckOkValue="districtCheck">
                <input-text placeholder="VA" v-model="form.district"/>
                </Validator>
              </div>
            </div>
            <div class="gridItems">
              <div class="itemTittleBox2">
                <span>Zip</span>
              </div>
              <div class="dorwDown2">
                <Validator type="number" :value="form.zip" :show="chickShow" @isCheckOkValue="zipCheck">
                <input-text placeholder="24175" v-model="form.zip"/>
                </Validator>
              </div>
            </div>
            <div class="gridItems2">
              <div class="itemTittleBox2">
                <span>Contact Address</span>
              </div>
              <div class="dorwDown2">
                <Validator type="text" :value="form.contactAddress" :show="chickShow" @isCheckOkValue="contactAddressCheck">
                <input-text placeholder="303 Meadowview Drive" v-model="form.contactAddress"/>
                </Validator>
              </div>
            </div>
          </div>
        </div>
        <div class="gridItems">
          <div class="fileUpLoadBox">
            <el-upload
              :on-success="onUploadSuccessImg2"
              :before-upload="beforeAvatarUpload2"
              :multiple="false"
              :action="avatarUrl"
              :data="{module:'idCard'}"
              :limit="1"
              class="uploadbtn">
              <ElUploadBtnStyle>
                <template v-slot:img>
                  <img src="@/assets/xuImg/idCard.png" v-if="!imgUrlBoolen2">
                  <img :src="form.imgUrl2" v-if="imgUrlBoolen2">
                </template>
              </ElUploadBtnStyle>
            </el-upload>
          </div>
          <div class="warringText" v-if="chickShow">
            <span>{{imgwarring2}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import InputText from '@/components/XuComponents/form/InputText'
import {CountryList,getDictById,CityList} from "@/axios/api/dict"
import ElUploadBtnStyle from '@/components/XuComponents/fileUpLoad/ElUploadBtnStyle'
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormTen',
  components: {
    SelectMenu,
    OptionMenu,
    InputText,
    ElUploadBtnStyle,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      countryList:[],
      cityList:[],
      countryList2:[],
      cityList2:[],

      //圖片
      avatarUrl:"http://13.114.63.198:9119/admin/oss/file/upload",
      imgwarring1:"*必須上傳檔案",
      imgwarring2:"*必須上傳檔案",
      imgUrlBoolen1:false,
      imgUrlBoolen2:false,

      form:{
        country:"Select",
        city:"Select",
        IdCardNumber:"",
        country2:"Select",
        city2:"Select",
        district:"",
        zip:"",
        contactAddress:"",
        imgUrl1:"",
        imgUrl2:"",
      },
      isCheckAllOkForm:{}
    }
  },
  methods:{
    country(item){
      this.form.country = item.name
    },
    city(item){
      this.form.city = item.enName
    },
    country2(item){
      this.form.country2 = item.name
      CityList(item.id).then(res =>{
        this.cityList2 = res.data.list
      })
    },
    city2(item){
      this.form.city2 = item.name
    },
    get(){
      CountryList().then(res =>{
        this.countryList = res.data.items
        this.countryList2 = res.data.items
      })
      getDictById(10000).then(res =>{
        this.cityList = res.data.list
      })
    },

    //圖片上傳
    onUploadSuccessImg(response){
      if(response.code==20000){
        this.form.imgUrl1 = response.data.url
        this.imgUrlBoolen1 = true
      }
      else{
        this.imgUrlBoolen1 = false
      }
    },
    onUploadSuccessImg2(response){
      if(response.code==20000){
        this.form.imgUrl2 = response.data.url
        this.imgUrlBoolen2 = true
      }
      else{
        this.imgUrlBoolen2 = false
      }
    },
    //圖片上傳前表單校驗
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.imgwarring1="*上傳證件只能是 JPG 格式!"
        this.isCheckAllOkForm.img1Check = false
      }
      else if(!isLt2M){
        this.imgwarring1="*上傳證件大小不能超過 2MB!"
        this.isCheckAllOkForm.img1Check = false
      }
      else{
        this.imgwarring1=""
        this.isCheckAllOkForm.img1Check = true
      }
      this.changPage()
      return isJPG && isLt2M;
    },
    beforeAvatarUpload2(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.imgwarring2="*上傳證件只能是 JPG 格式!"
        this.isCheckAllOkForm.img2Check = false
      }
      else if(!isLt2M){
        this.imgwarring2="*上傳證件大小不能超過 2MB!"
        this.isCheckAllOkForm.img2Check = false
      }
      else{
        this.imgwarring2=""
        this.isCheckAllOkForm.img2Check = true
      }
      this.changPage()
      return isJPG && isLt2M;
    },
    countryCheck(CheckBoolen){
      this.isCheckAllOkForm.countryCheck = CheckBoolen
      this.changPage()
    },
    cityCheck(CheckBoolen){
      this.isCheckAllOkForm.cityCheck = CheckBoolen
      this.changPage()
    },
    IdCardNumberCheck(CheckBoolen){
      this.isCheckAllOkForm.IdCardNumberCheck = CheckBoolen
      this.changPage()
    },
    country2Check(CheckBoolen){
      this.isCheckAllOkForm.country2Check = CheckBoolen
      this.changPage()
    },
    city2Check(CheckBoolen){
      this.isCheckAllOkForm.city2Check = CheckBoolen
      this.changPage()
    },
    districtCheck(CheckBoolen){
      this.isCheckAllOkForm.districtCheck = CheckBoolen
      this.changPage()
    },
    zipCheck(CheckBoolen){
      this.isCheckAllOkForm.zipCheck = CheckBoolen
      this.changPage()
    },
    contactAddressCheck(CheckBoolen){
      this.isCheckAllOkForm.contactAddressCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.img1Check == true 
      && this.isCheckAllOkForm.img2Check == true 
      && this.isCheckAllOkForm.countryCheck == true 
      && this.isCheckAllOkForm.cityCheck == true 
      && this.isCheckAllOkForm.IdCardNumberCheck == true 
      && this.isCheckAllOkForm.country2Check == true 
      && this.isCheckAllOkForm.city2Check == true 
      && this.isCheckAllOkForm.districtCheck == true 
      && this.isCheckAllOkForm.zipCheck == true 
      && this.isCheckAllOkForm.contactAddressCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
    this.get()
  }
}
</script>

<style scoped>
.openAccountFormTen{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  width: 861px;
  max-width: 861px;
}
.itemTittleBox{
  width: 100%;
  height: 40px;
  background: #333333;
  border-radius: 9px;
  display: flex;
}
.center{
  height: 100%;
  margin: 0 auto;
  width: 90%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #FBFBFB;
}
.itemBox{
  padding-top: 20px;
  margin-bottom: 10px;
}
.textBox{
  width: 90%;
  margin-left: 5%;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}
.iconsBox{
  width: 90%;
  margin-left: 5%;
  height: 158px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
}
.gridItems{
  width: 100%;
  height: 100%;
}
.dorwDownBox{
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.dorwDown{
  margin-right: 40px;
}
.inputBox{
  width: 70%;
}
.itemTittleBox2{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.fileUpLoadBox{
  width: 246px;
  height: 138px;
  border-radius: 7px;
  overflow: hidden;
}
img{
  width: 100%;
  height: 100%;
}
.iconsBox2{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}
.gridItems2{
  grid-column: 1/3;
  grid-row: 2/3;
}
.dorwDown2{
  width: 90%;
}
.warringText{
  text-align: right;
  margin-left: 20px;

  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
}
.uploadbtn{
  width: 100%;
  height: 100%;
}
img{
  width: 100%;
}
</style>
