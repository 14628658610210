<template>
  <div class="openAccountFormEight">
    <div class="textBigBox">
      <div class="itemBox">
        <div class="itemTittleBox">
          <div class="center">
            <span>市場許可</span>
          </div>
        </div>
      </div>
      <div class="gridLow">
        <div class="gridLowItem">
          <div class="marginLeft">
            <div class="itemsTittle">
              <span>勾選你想要的交易地點</span>
              &nbsp;
              <span class="warringText" v-if="chickShow">{{warring}}</span>
            </div>
          </div>
        </div>
        <div class="gridLowItem">
          <div class="gridLow2">
            <div class="gridLowItem2">
              <div class="inputBigBox">
                <div class="inputBox">
                  <input class="checkbox" type="checkbox" v-model="allAsia" @change="allClick1">
                  <span>所有亞洲</span>
                </div>
              </div>
            </div>
            <div class="gridLowItem2">
              <div class="gridLow3">
                <div class="inputBigBox2" v-for="item in inputItems1" :key="item.id">
                  <div class="inputBox2" >
                    <input class="checkbox2" type="checkbox" v-model="item.value" @change="forItemsClick1">
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gridLowItem">
          <div class="gridLow2">
            <div class="gridLowItem2">
              <div class="inputBigBox">
                <div class="inputBox">
                  <input class="checkbox" type="checkbox" v-model="allEurope" @change="allClick2">
                  <span>所有歐洲</span>
                </div>
              </div>
            </div>
            <div class="gridLowItem2">
              <div class="gridLow3">
                <div class="inputBigBox2" v-for="item in inputItems2" :key="item.id">
                  <div class="inputBox2">
                    <input class="checkbox2" type="checkbox" v-model="item.value" @change="forItemsClick2">
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gridLowItem">
          <div class="gridLow2">
            <div class="gridLowItem2">
              <div class="inputBigBox">
                <div class="inputBox">
                  <input class="checkbox" type="checkbox" v-model="allNorthAmerica" @change="allClick3">
                  <span>所有北美</span>
                </div>
              </div>
            </div>
            <div class="gridLowItem2">
              <div class="gridLow3">
                <div class="inputBigBox2" v-for="item in inputItems3" :key="item.id">
                  <div class="inputBox2">
                    <input class="checkbox2" type="checkbox" v-model="item.value" @change="forItemsClick3">
                    <span>{{item.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="itemTittleBox">
          <div class="center">
            <span>保證金類別</span>
          </div>
        </div>
      </div>
      <div class="iconsBox">
        <div class="icons">
          <div class="icon">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.marginType1" :show="chickShow" @isCheckOkValue="marginType1Check">
              <select-menu :title="form.marginType1" :width='137'>
                <option-menu v-for="item in marginTypeList1" :key="item.id" @click="marginType1(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
          <div class="icon">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.marginType2" :show="chickShow" @isCheckOkValue="marginType2Check">
              <select-menu :title="form.marginType2" :width='137'>
                <option-menu v-for="item in marginTypeList2" :key="item.id" @click="marginType2(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
          <div class="icon">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.marginType3" :show="chickShow" @isCheckOkValue="marginType3Check">
            <select-menu :title="form.marginType3" :width='137'>
              <option-menu v-for="item in marginTypeList3" :key="item.id" @click="marginType3(item)">{{item.enName}}</option-menu>
            </select-menu>
            </Validator>
          </div>
        </div>
      </div>
      <div class="itemBox">
        <div class="itemTittleBox">
          <div class="center">
            <span>債券</span>
          </div>
        </div>
      </div>
      <div class="iconsBox">
        <div class="icons">
          <div class="icon">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.bond1" :show="chickShow" @isCheckOkValue="bond1Check">
              <select-menu :title="form.bond1" :width='137'>
                <option-menu v-for="item in bondList1" :key="item.id" @click="bond1(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
          <div class="icon">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.bond2" :show="chickShow" @isCheckOkValue="bond2Check">
              <select-menu :title="form.bond2" :width='137'>
                <option-menu v-for="item in bondList2" :key="item.id" @click="bond2(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
          <div class="icon">
            <Validator  type="drowDownMeun" defaultName="Select" :titleName="form.bond3" :show="chickShow" @isCheckOkValue="bond3Check">
              <select-menu :title="form.bond3" :width='137'>
                <option-menu v-for="item in bondList3" :key="item.id" @click="bond3(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import {getDictById} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'OpenAccountFormEight',
  components: {
    SelectMenu,
    OptionMenu,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      inputItems1:[
        {
          name:"澳大利亞",
          value:false
        },
        {
          name:"韓國",
          value:false
        },
        {
          name:"日本",
          value:false
        },
        {
          name:"新加坡",
          value:false
        },
        {
          name:"台灣",
          value:false
        },
        {
          name:"香港",
          value:false
        },
      ],
      inputItems2:[
        {
          name:"比利時",
          value:false
        },
        {
          name:"法國",
          value:false
        },
        {
          name:"德國",
          value:false
        },
        {
          name:"義大利",
          value:false
        },
        {
          name:"荷蘭",
          value:false
        },
        {
          name:"挪威",
          value:false
        },
        {
          name:"西班牙",
          value:false
        },
        {
          name:"瑞士",
          value:false
        },
        {
          name:"瑞士",
          value:false
        },
        {
          name:"英國",
          value:false
        },
      ],
      inputItems3:[
        {
          name:"加拿大",
          value:false
        },
        {
          name:"美國(國際石油交易所)",
          value:false
        },
        {
          name:"美國",
          value:false
        },
        {
          name:"墨西哥",
          value:false
        },
        {
          name:"美國(期貨-算法執行場所)",
          value:false
        },
        {
          name:"美國(加密貨幣)",
          value:false
        },
      ],
      marginTypeList1:[],
      marginTypeList2:[],
      marginTypeList3:[],
      bondList1:[],
      bondList2:[],
      bondList3:[],

      warring:"*必須至少選一項",

      allAsia:false,
      allEurope:false,
      allNorthAmerica:false,
      marketCountry1:"",
      marketCountry2:"",
      marketCountry3:"",

      form:{
        marketCountry:"",
        marginType1:"Select",
        marginType2:"Select",
        marginType3:"Select",
        bond1:"Select",
        bond2:"Select",
        bond3:"Select",
      },
      isCheckAllOkForm:{},
      isFormOk:false,
    }
  },
  methods:{
    marginType1(item){
      this.form.marginType1 = item.enName
    },
    marginType2(item){
      this.form.marginType2 = item.enName
    },
    marginType3(item){
      this.form.marginType3 = item.enName
    },
    bond1(item){
      this.form.bond1 = item.enName
    },
    bond2(item){
      this.form.bond2 = item.enName
    },
    bond3(item){
      this.form.bond3 = item.enName
    },
    get(){
      getDictById(23000).then(res =>{
        this.marginTypeList1 = res.data.list
        this.bondList1 = res.data.list
      })
      getDictById(23100).then(res =>{
        this.marginTypeList3 = res.data.list
        this.bondList3 = res.data.list
      })
      getDictById(23200).then(res =>{
        this.marginTypeList2 = res.data.list
        this.bondList2 = res.data.list
      })
    },
    allClick1(){
      if(this.allAsia == true){
        for(let i=0; i<this.inputItems1.length; i++){
          this.inputItems1[i].value = true
        }
      }
      else{
        this.marketCountry1=""
        for(let i=0; i<this.inputItems1.length; i++){
          this.inputItems1[i].value = false
        }
      }
      this.forItemsClick1()
    },
    forItemsClick1(){
      this.marketCountry1=""
      for(let j=0; j<this.inputItems1.length; j++){
        if(this.inputItems1[j].value == true){
          this.marketCountry1 = this.marketCountry1 + "_" + this.inputItems1[j].name
        }
      }
      this.combineAllMarketCountry()
    },
    allClick2(){
      if(this.allEurope == true){
        for(let i=0; i<this.inputItems2.length; i++){
          this.inputItems2[i].value = true
        }
      }
      else{
        this.marketCountry2=""
        for(let i=0; i<this.inputItems2.length; i++){
          this.inputItems2[i].value = false
        }
      }
      this.forItemsClick2()
    },
    forItemsClick2(){
      this.marketCountry2=""
      for(let j=0; j<this.inputItems2.length; j++){
        if(this.inputItems2[j].value == true){
          this.marketCountry2 = this.marketCountry2 + "_" + this.inputItems2[j].name
        }
      }
      this.combineAllMarketCountry()
    },
    allClick3(){
      if(this.allNorthAmerica == true){
        for(let i=0; i<this.inputItems3.length; i++){
          this.inputItems3[i].value = true
        }
      }
      else{
        this.marketCountry3=""
        for(let i=0; i<this.inputItems3.length; i++){
          this.inputItems3[i].value = false
        }
      }
      this.forItemsClick3()
    },
    forItemsClick3(){
      this.marketCountry3=""
      for(let j=0; j<this.inputItems3.length; j++){
        if(this.inputItems3[j].value == true){
          this.marketCountry3 = this.marketCountry3 + "_" + this.inputItems3[j].name
        }
      }
      this.combineAllMarketCountry()
    },
    combineAllMarketCountry(){
      this.form.marketCountry = this.marketCountry1 + this.marketCountry2 + this.marketCountry3
      if(this.form.marketCountry == ""){
        this.isCheckAllOkForm.marketCountryCheck = false
        this.warring = "*必須至少選一項"
      }
      else{
        this.isCheckAllOkForm.marketCountryCheck = true
        this.warring = ""
      }
      this.changPage()
    },
    marginType1Check(CheckBoolen){
      this.isCheckAllOkForm.marginType1Check = CheckBoolen
      this.changPage()
    },
    marginType2Check(CheckBoolen){
      this.isCheckAllOkForm.marginType2Check = CheckBoolen
      this.changPage()
    },
    marginType3Check(CheckBoolen){
      this.isCheckAllOkForm.marginType3Check = CheckBoolen
      this.changPage()
    },
    bond1Check(CheckBoolen){
      this.isCheckAllOkForm.bond1Check = CheckBoolen
      this.changPage()
    },
    bond2Check(CheckBoolen){
      this.isCheckAllOkForm.bond2Check = CheckBoolen
      this.changPage()
    },
    bond3Check(CheckBoolen){
      this.isCheckAllOkForm.bond3Check = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.marketCountryCheck == true 
      && this.isCheckAllOkForm.marginType1Check == true 
      && this.isCheckAllOkForm.marginType2Check == true 
      && this.isCheckAllOkForm.marginType3Check == true 
      && this.isCheckAllOkForm.bond1Check == true 
      && this.isCheckAllOkForm.bond2Check == true 
      && this.isCheckAllOkForm.bond3Check == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
    this.get()
  },
}
</script>

<style scoped>
.openAccountFormEight{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  width: 861px;
  max-width: 861px;
}
.itemTittleBox{
  width: 100%;
  height: 40px;
  background: #333333;
  border-radius: 9px;
  display: flex;
}
.center{
  height: 100%;
  margin: 0 auto;
  width: 90%;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  color: #FBFBFB;
}
.itemBox{
  padding-top: 15px;
  margin-bottom: 15px;
}
.gridLow{
  width: 100%;
  height: 285px;
  border: 1px solid #E5E5E5;
  display: grid;
  grid-template-columns: 1fr 1.5fr 2fr;
  grid-template-rows: 1fr 5fr;
}
.gridLowItem{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
}
.gridLowItem:nth-child(1){
  grid-column: 1/4;
  grid-row: 1/2;
}
.gridLowItem:nth-child(2){
  grid-column: 1/2;
  grid-row: 2/3;
}
.gridLowItem:nth-child(3){
  grid-column: 2/3;
  grid-row: 2/3;
}
.gridLowItem:nth-child(4){
  grid-column: 3/4;
  grid-row: 2/3;
}
.marginLeft{
  margin-left: 20px;
}
.itemsTittle{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
}
.gridLow2{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 5fr;
}
.gridLowItem2:nth-child(1){
  grid-column: 1/2;
  grid-row: 1/2;
}
.gridLowItem2:nth-child(2){
  grid-column: 1/2;
  grid-row: 2/3;
}
.gridLow3{
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
}
.inputBigBox, .inputBigBox2{
  width: 100%;
  height: 100%;
}
.inputBox{
  display: flex;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  padding-top: 9px;
  margin-left: 10px;
}
.inputBox2{
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 9px;
  margin-left: 10px;
}
.checkbox{
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.checkbox2{
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.iconsBox{
  width: 100%;
  display: flex;
}
.icons{
  margin: 0 auto;
  display: flex;
}
.icon{
  margin: 0 5px;
}
.warringText{
  height: 100%;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  color: #FF0000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
}
</style>
